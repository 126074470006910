import React, { Component } from 'react';
import mobile from '../../../images/mobile.svg';
// import lines from '../../../images/lines-2.svg';
import './style.scss';
// import { ContactUsContext } from '../../../App';
import ServicesRowItem from '../ServicesBlocks/ServicesRowItem';
import { ServiceDetailsData } from '../../../Mock/serviceDetails';
import { ServiceUrls } from '../../../Mock';
import ReadMoreBlock from '../ServicesBlocks/ReactMoreBlock';

export default class MobileDevelopment extends Component {
  state = {
    isShown: false,
  }

  onReadMore = () => {
    console.log('onReadMorelick');
    this.setState({ isShown: true });
  }

  render() {
    const { isShown } = this.state;
    return (
      <div className="service-item isWeb">
        <h2 className="container-top__title">
          <img src={mobile} alt="Icon Web" />
          MOBILE APPS DEVELOPMENT
        </h2>
        <div className="service-item__info">

          <div className="service-item__description">


            <p>
              Let’s get your business at your clients’ fingertips. We will create a mobile application for them so smooth and responsive,
              they will adore it and will return to you again and again.
            </p>
            <p>
            To make such an app, it will take your excellent business idea, cutting-edge technologies, and our team of professionals.
            They will wield these technologies to turn your idea into a unique mobile application that will bring your business right in front of your customers.
            </p>
          </div>
          <div className="read-more-mobile">
            <ReadMoreBlock readMore={ServiceDetailsData[ServiceUrls.MobileAppsDevelopment].readMore} isShown={isShown} onReadMorelick={this.onReadMore} />
          </div>
          <div className="service-item__options">
            <ServicesRowItem items={ServiceDetailsData[ServiceUrls.MobileAppsDevelopment].options} rowNum={1} />
            <ServicesRowItem items={ServiceDetailsData[ServiceUrls.MobileAppsDevelopment].options} rowNum={2} />
          </div>

        </div>
        <div className="read-more-web">
          <ReadMoreBlock readMore={ServiceDetailsData[ServiceUrls.MobileAppsDevelopment].readMore} isShown={isShown} onReadMorelick={this.onReadMore} />
        </div>
      </div>
    );
  }
}
