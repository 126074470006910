/* eslint-disable */
export const WorksItems = [
  {
    id: 0,
    name: '30 pills',
    image: '30_pills',
    url: '30-pills',
  },
  {
    id: 1,
    name: 'VanGo',
    image: 'vango',
    url: 'van-go'
  },
  {
    id: 2,
    name: 'Blir Fint',
    image: 'BLIRFINT',
    url: 'blir-fint'
  },
  {
    id: 3,
    name: 'iKidDo',
    image: 'IKIDDO',
    url: 'ikiddo'
  },
  {
    id: 4,
    name: 'Meld Bilskade',
    image: 'meldBildskade',
    url: 'meld-bildskade'
  },
  {
    id: 5,
    name: 'KarriereStart',
    image: 'karrierestart',
    url: 'karriere-start'
  },
  {
    id: 6,
    name: 'KAM',
    image: 'kam',
    url: 'kam'
  },
  {
    id: 7,
    name: 'Foodlist',
    image: 'foodlist',
    url: 'foodlist'
  },
  {
    id: 8,
    name: 'Parrotplay',
    image: 'parrotplay',
    url: 'parrotplay'
  },
  {
    id: 9,
    name: 'Gratify',
    image: 'gratify',
    url: 'gratify'
  },
  {
    id: 10,
    name: 'Seequre',
    image: 'seequre',
    url: 'seequre'
  },
  {
    id: 11,
    name: 'Eduspike',
    image: 'EDUSPIKE',
    url: 'eduspike'
  },
  {
    id: 12,
    name: 'Charlie!',
    image: 'charlie',
    url: 'charlie'
  },
  {
    id: 13,
    name: 'Getwell',
    image: 'getwell',
    url: 'getwell'
  },
  {
    id: 14,
    name: 'Sensee',
    image: 'sensee',
    url: 'sensee'
  }
];
