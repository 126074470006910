/* eslint-disable */
import React, { Component } from 'react';
import Form from '../Form/Form';
import './style.scss';
import phone from '../../images/icon-black-phone-call.svg';
import mail from '../../images/icon-mail.svg';
import buildings from '../../images/icon-buildings.svg';
import close from '../../images/icon-close.svg';
import SendForm from "../../Actions/SendForm";


export default class ModalForm extends Component {
  render() {
    const { onCloseModal } = this.props;

    const onFormSubmit =  (request) => {
      SendForm(request);
      if (typeof onCloseModal !== 'undefined')
        onCloseModal();
      else
        window.history.back();
    };

    return (
      <div className="modal">
        <h2 className="modal__title">Do you want to start a project?
          <span>Don`t wait until tomorrow, hit us up now</span>
        </h2>
        <img src={close} alt="Icon Close" className="modal__close" onClick={onCloseModal}/>
        <div className="modal__wrapper">
          <Form onSubmit={onFormSubmit}/>
          <div className="modal__right contact">
            <div className="contact__item">
              <img src={phone} alt="Icon Phone"/>
              <p>Call Us
                <a href="tel:+1(813)9900844" className="contact__phone"><span>+1 (813) </span>99 00 844</a>
              </p>
            </div>
            <div className="contact__item">
              <img src={mail} alt="Icon Mail"/>
              <p>Email Us
                <a href="mailto:info@unicreo.com" className="contact__email">info@unicreo.com</a>
              </p>
            </div>
            <div className="contact__item">
              <img src={buildings} alt="Icon Address"/>
              <p className="contact__address">
                Visit Us
                <span>9d Sichovykh Strliltsiv str.,</span>
                <span>Office 6,</span>
                <span>49000 Dnipro, Ukraine</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
