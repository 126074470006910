
import React, { Component } from 'react';
import web from '../../../images/web.svg';
import './style.scss';
import ServicesRowItem from '../ServicesBlocks/ServicesRowItem';
import { ServiceDetailsData } from '../../../Mock/serviceDetails';
import { ServiceUrls } from '../../../Mock';
import ReadMoreBlock from '../ServicesBlocks/ReactMoreBlock';

export default class WebDevelopment extends Component {
  state = {
    isShown: false,
  }

  onReadMore = () => {
    console.log('onReadMorelick');
    this.setState({ isShown: true });
  }

  render() {
    const { isShown } = this.state;
    return (
      <div className="service-item isWeb">
        <h2 className="container-top__title">
          <img src={web} alt="Icon Web" />
          WEB DEVELOPMENT
        </h2>
        <div className="service-item__info">

          <div className="service-item__description">

            <p>We create simple, intuitive, and beautiful web applications for startups and enterprises.</p>
            <p>
              The right web app will help you strengthen your relationship with customers, scale your operations, and simply make money.
              At Unicreo, we know how to build you one. No matter where your users opened it – on a widescreen monitor, tablet, or an
              iPhone while waiting in line for a cup of coffee – they will fall in love with it at a glance.
            </p>
            <p>
              To craft you such an app, we take full care of it, from prototyping to delivery and support.
              Our partnership begins with a series of in-depth discussions.
              Together we establish a profound understanding of your needs and a clear vision of how to achieve them.
            </p>

          </div>
          <div className="read-more-mobile">
            <ReadMoreBlock readMore={ServiceDetailsData[ServiceUrls.WebDevelopment].readMore} isShown={isShown} onReadMorelick={this.onReadMore} />
          </div>
          <div className="service-item__options">
            <ServicesRowItem items={ServiceDetailsData[ServiceUrls.WebDevelopment].options} rowNum={1} />
            <ServicesRowItem items={ServiceDetailsData[ServiceUrls.WebDevelopment].options} rowNum={2} />
            <ServicesRowItem items={ServiceDetailsData[ServiceUrls.WebDevelopment].options} rowNum={3} />
          </div>

        </div>
        <div className="read-more-web">
          <ReadMoreBlock readMore={ServiceDetailsData[ServiceUrls.WebDevelopment].readMore} isShown={isShown} onReadMorelick={this.onReadMore} />
        </div>
      </div>
    );
  }
}
