/* eslint-disable */
import React, { Component } from 'react';
import HeaderBg from '../../images/landings/ikiddo_back-2.jpg';
import Logo from '../../images/landings/ikiddo-logo.png';
import Screen from '../../images/landings/ikiddo-header-screen-first@2x.png';
import SecondScreen from '../../images/landings/ikiddo-header-screen-second@2x.png';
import MainScreen from '../../images/landings/ikiddo-screen-nexus@2x.png';
import IconIos from '../../images/landings/ikiddo-icon-ios.svg';
import IconAndroid from '../../images/landings/ikiddo-icon-android.svg';
import IconWeb from '../../images/landings/ikiddo-icon-web.svg';
import Team from '../../images/landings/ikiddo_team.png';
import './style.scss';

class IkidDo extends Component {
  render() {
    return (
      <div className="kid">
        <div className="landing-header kid__header">
          <img className="kid__header-bg" src={HeaderBg} alt="IKidDo"/>
          <div className="kid__logo">
            <picture>
              <img src={Logo} alt="IKidDo Logo" />
            </picture>
          </div>
          <div className="kid__screens">
            <img src={Screen} alt="Screen" />
            <img src={SecondScreen} alt="Screen" />
          </div>
        </div>
        <div className="kid__main">
          <div className="kid__text">
            <h2 className="kid__text-title">
              Make your housework funny!
            </h2>
            <p>iKidDo is the social app which allows You to bring order to your home tasks.</p>
            <p>Interactive task manager helps you to interest your kids in doing housework.</p>
            <p>You can simply create the family, add members there and assign tasks to any of them.</p>
          </div>
          <img className="kid__main-screen" src={MainScreen} alt="Screen" />
        </div>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-wrapper">
          <div className="landing-team">
            <img src={Team}  alt="Team" />
          </div>
          <div className="kid__technologies-wrapper">
            <div className="kid__technologies">
              <img src={IconIos} alt="IOS"/>
              <div className="technologies isRightAlign">
                <span className="technology">Storage: CoreData</span>
                <span className="technology">Language: Swift </span>
                <span className="technology">Firebase Messaging</span>
                <span className="technology">Crashlytics</span>
                <span className="technology">RESideMenu</span>
              </div>
            </div>
            <div className="kid__technologies">
              <img src={IconAndroid} alt="ANDROID"/>
              <div className="technologies isRightAlign">
                <span className="technology">Room DB</span>
                <span className="technology">Mosby</span>
                <span className="technology">Dagger2</span>
                <span className="technology">rxJava</span>
                <span className="technology">Custom Recyclerview</span>
                <span className="technology">Firebase</span>
                <span className="technology">Camerakit</span>
              </div>
            </div>
            <div className="kid__technologies">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies isRightAlign">
                <span className="technology">ASP.NET Core 2.0</span>
                <span className="technology">EF Core</span>
                <span className="technology">Web API</span>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-info isBlack">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Pixels & Bytes AS</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from January 2018 to now</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default IkidDo;
