/* eslint-disable */
import React, {Component} from 'react';
import './style.scss';
import Logo from '../../images/landings/karrierestart-logo.svg';
import Laptop from '../../images/landings/karrierestart-main-laptop@2x.png';
import Team from '../../images/landings/ks_team.png';
import Bg from '../../images/landings/karrierestart_back.jpg';

class KarriereStart extends Component {
  render() {
    return (
      <div className="karriere">
        <div className="landing-header karriere__header">
          <img src={Bg} alt=""/>
          <div className="karriere__logo">
            <img src={Logo} alt="KarriereStart Logo"/>
            <div>
              <h1>KarriereStart.no</h1>
              <p>Leading Norwegian job portal which was made both for
                people who are searching for a job and companies.</p>
            </div>
          </div>
        </div>
        <h2 className="karriere__main-title">Give your career a boo!</h2>
        <div className="karriere__main">
          <div className="karriere__text">
            <img src={Laptop} alt="Laptop" align="right"/>
            <p className="karriere__description"> It’s known more than 80% of jobs are never advertised, it is evident
              that many
              job seekers miss the best career opportunities and employers miss the best
              candidates. KarriereStart helps to prevent these statistics.
            </p>
            <p className="karriere__description">
              KarriereStart collects and structures all the information
              about the job market, industries, occupations and
              employers throughout Norway. System helps to search for
              the most suitablecandidates for the position, plus it
              searches jobs that match desires and experience. One of
              the features of this professional social networking website
              is extended tools for analysts. Analyze with specified
              criteria shows the compatibility of the candidate with the
              vacancy.
            </p>
          </div>
        </div>
        <div className="landing-team">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span>
          </h3>
          <img src={Team} alt="Team"/>
        </div>
        <div className="karriere__technologies-wrapper">
          <div className="technologies">
            <span className="technology">LESS</span>
            <span className="technology">.NET</span>
            <span className="technology">CSS</span>
            <span className="technology">C#</span>
            <span className="technology">JavaScript</span>
            <span className="technology">jQuery</span>
            <span className="technology">ASP.NET MVC</span>
            <span className="technology">Facebook API</span>
            <span className="technology">SQL Server</span>
            <span className="technology">Knockout Js</span>
            <span className="technology">Ajax</span>
            <span className="technology">IINQ</span>
            <span className="technology">MS Visual Studio</span>
            <span className="technology">Linq to SQL</span>
            <span className="technology">MS SQL Server Management Studio</span>
            <span className="technology">LINQPad</span>
            <span className="technology">SVN</span>
            <span className="technology">HTML</span>
          </div>
        </div>
        <div className="landing-info isBlack">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Bjarne</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2011 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="http://karrierestart.no" target="_blank">karrierestart.no</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default KarriereStart;
