/* eslint-disable */
import React, { Component } from 'react'
import './style.scss';
import { WorksItems } from '../../Mock/works';
import GalleryItem from '../Works/GalleryItem';

export default class Works extends Component {
  render() {
    return (
      <div className='works-page'>
        <div className='works-items-container'>
          {WorksItems.map(item => {
            return <GalleryItem item={item} key={item.id}/>
          })}
        </div>
      </div>
    )
  }
}
