/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/van-go-logo.svg';
import HeaderBg from '../../images/landings/vangp-header.jpg'
import FirstScreen from '../../images/landings/van-go-header-first-screen@2x.png';
import SecondScreen from '../../images/landings/van-go-header-second-screen@2x.png';
import MainPicture from '../../images/landings/van-go-dribbble.jpg';
import Team from '../../images/landings/vango_team.png';
import IconWeb from '../../images/landings/vango-icon-web.svg';
import IconMob from '../../images/landings/vango-icon-mobile.svg';

class Vango extends Component {
  render() {
    return (
      <div className="vango">
        <div className="landing-header vango__header">
          <img className="vango__header-bg" src={HeaderBg} alt="Vango"/>
            <h1 className="vango__logo">
              <img src={Logo} loading="lazy" alt="Vango Logo" />
            </h1>
            <div className="vango__screens">
              {/*<picture className="vango__screen">*/}
                <img src={FirstScreen} loading="lazy" alt="Screen" />
              {/*</picture>*/}
              {/*<picture className="vango__screen">*/}
                <img src={SecondScreen} loading="lazy" alt="Screen" />
              {/*</picture>*/}
            </div>
        </div>
        <div className="vango__main">
          <img src={MainPicture} loading="lazy" alt="Dribble" align="left"/>
            <h2 className="vango__title">Parents,
              <span>We heard you.</span></h2>
            <p className="vango__description"><span>Rides for your preteens</span> & teens, when and where you need them.
              We do the vetting, so you don't have to. Our team of carefully vetted
              drivers are nannies, teachers, and babysitters. All of our drivers have at
              least three years of childcare experience, and are carefully vetted by
              our team via family references, background + driving record checks,
              and vehicle inspections.
            </p>
            <p className="vango__description">
              Recurring rides, one-off rides, last-minute rides... <span>you can post any</span>
              <span>ride, any time.</span> Specify where and when you want your teen to be picked
              up. Your request goes out to our trusted, vetted pool of drivers.
              Once a driver accepts your request, you get alerted, and are able to see
              their full profile.
            </p>
        </div>
        <hr className="separator"/>
        <div className="landing-team">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span></h3>
          <img src={Team} loading="lazy" alt="Team" />
        </div>
        <div className="vango__technologies-wrapper">
          <div className="vango__technologies">
            <img src={IconMob} loading="lazy" alt="MOBILE"/>
            <div className="technologies">
              <span className="technology">React</span>
              <span className="technology">React navigation</span>
              <span className="technology">React Native</span>
              <span className="technology">ES6</span>
              <span className="technology">Redux</span>
            </div>
          </div>
          <div className="vango__technologies">
            <img src={IconWeb} loading="lazy" alt="WEB"/>
            <div className="technologies">
              <span className="technology">Node.JS</span>
              <span className="technology">Redis</span>
              <span className="technology">Postgre SQL</span>
              <span className="technology">Retool</span>
              <span className="technology">Stripe Client</span>
              <span className="technology">Google Places API</span>
            </div>
          </div>
        </div>
        <div className="landing-info isPink">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Mishael</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from May 2018 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="https://www.ridevango.com" target="_blank">ridevango.com</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Vango;
