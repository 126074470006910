/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Bg from '../../images/landings/foodlist-header.jpg';
import Logo from '../../images/landings/foodlist-logo.svg';
import Screen from '../../images/landings/phones_foodlist.png';
import MainScreen from '../../images/landings/foodlist-main-mobile@2x.png';
import Team from '../../images/landings/foodlist_team.png';
import IconIos from '../../images/landings/foodlist-icon-ios.svg';
import IconAndroid from '../../images/landings/foodlist-icon-android.svg';
import IconWeb from '../../images/landings/foodlist-icon-web.svg';

class Foodlist extends Component {
  render() {
    return (
      <div className="foodlist">
        <div className="landing-header foodlist__header">
          <img className="foodlist__header-bg" src={Bg} alt="Foodlist"/>
          <h1 className="foodlist__logo">
            <img src={Logo} alt="Foodlist Logo" />
          </h1>
          <div className="foodlist__screens">
            <img src={Screen} alt="Screen" />
          </div>
        </div>
        <div className="foodlist__main">
          <img src={MainScreen} alt="Screen" align="left"/>
          <div className="foodlist__text">
            <p className="foodlist__description"><strong>Foodlist</strong>, a <span>social application</span> that allows people to buy products that are close
              to their end date of usage in Norwegian shops, was built from scratch .
              With the help of Foodlist, <span>users are able to share information</span> about food that
              is in danger of becoming food waste . Read on to see how the product allowed
              shops to create their own lists of such products or products that were donated
              to the charity  while charity can negotiate with shops about products supply.
              Save money, help the environment and support people in need!
            </p>
            <p className="foodlist__description">
              Like many businesses, customer and our team faced some challenges, but
              problems weren`t global. Minor inconsistencies between application design
              and declared functionality became challenging. Also, we found an unusual
              logic of product lifecycle according to time and different types of users.
            </p>
            <p className="foodlist__description">
              By examining challenge, we were able to solve his problem with the help of
              Project Manager. Communication with client directly helped to figure out all details.
            </p>
          </div>
        </div>
        <hr className="separator"/>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-wrapper">
          <div className="landing-team">
            <img src={Team} alt="Team"/>
          </div>
          <div className="foodlist__technologies-wrapper">
            <div className="foodlist__technologies">
              <img src={IconAndroid} alt="ANDROID"/>
              <div className="technologies isRightAlign">
                <span className="technology">MVP</span>
                <span className="technology">Dagger2</span>
                <span className="technology">REST client</span>
                <span className="technology">Google Services API</span>
                <span className="technology">FCM</span>
                <span className="technology">Geolocation</span>
              </div>
            </div>
            <div className="foodlist__technologies">
              <img src={IconIos} alt="IOS"/>
              <div className="technologies isRightAlign">
                <span className="technology">REST client</span>
                <span className="technology">FCM</span>
                <span className="technology">Google Services API</span>
                <span className="technology">Geolocation</span>
              </div>
            </div>
            <div className="foodlist__technologies">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies isRightAlign">
                <span className="technology">ASP.NET MVC</span>
                <span className="technology">ASP.NET Web API 2</span>
                <span className="technology">Bootstrap</span>
                <span className="technology">Entity Framework</span>
                <span className="technology">jQuery</span>
                <span className="technology">Stripe.Net</span>
                <span className="technology">Firebase Cloud Messaging</span>
                <span className="technology">Stripe.js</span>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-info isGreen">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Tomas Elton (Norway founder)</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>fall 2016</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="http://www.foodlist.no  " target="_blank">foodlist.no</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Foodlist;
