/* eslint-disable */
import React, { Component } from 'react'
import './style.scss';
import about from '../../images/icon-about-us.svg';
import Team from '../Team';
import Alex from '../../images/about-us-alex.jpg';
import Nick from '../../images/about-us-nick.jpg';
import { ContactUsContext } from '../../App';


export default class AboutUs extends Component {
  render() {
    return (
      <div className='about-us-page'>
        <div className="about">
          <div className="about__wrapper">
            <h2 className="about__title"><img src={about} alt="About Us"/>ABOUT US</h2>
            <p>
              <strong>UNICREO</strong> was founded in 2010 by two
              IT geeks Nick and Alex, who have always been guided
              by motto — provide a synergy, not a service, be a partner,
              not a resource.
            </p>
            <p>
              At <strong>UNICREO</strong>, relationships with our clients
              and valuable results are top priorities. We keep the code
              simple, test often and deliver a cost-effective as well
              as market ready solutions for your business. We offer
              a full-cycle development together with sleek UI/UX,
              project management, quality assurance, and long-term support.
            </p>
            <p>
              We team up with startups, ISV (independent software vendors)
              and enterprises from around the world. Our services range
              includes engineering services: web, mobile and UI/UX
              development (team booking); custom turnkey solutions;
              MVP and prototype development; legacy IT infrastructure
              evaluation, process and code review.
            </p>
            <ContactUsContext.Consumer>
              {({ handleContactUsModalOpen }) => (
                <a onClick={handleContactUsModalOpen} className="">Got an idea? We've got talents!
                  <br/>
                  Reach out to us!
                </a>
              )}
            </ContactUsContext.Consumer>
          </div>
          <h3 className="about__blockquote">
            <span>CLIENTS TRUST US</span>
            with the full-cycle development starting from the raw idea to reliable market-ready product
          </h3>
        </div>
        <div className="about__photos">
          <div className="photos">
            <div>
              <img src={Alex} alt="Alex"/>
              <span>Alex</span>
            </div>
            <div>
              <img src={Nick} alt="Nick"/>
              <span>Nick</span>
            </div>
          </div>
          <p>is not easy, but we make it easy</p>
        </div>
        <Team />
      </div>
    )
  }
}
