/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/meldbilskade-logo.svg';
import WhitePhone from '../../images/landings/iPhone_meltbildskade.svg';
import RedPhone from '../../images/landings/Phone_red_meltbildskade.svg';
import Team from '../../images/landings/meltbildskade_team.png';
import IconMob from '../../images/landings/meldbilskade-icon-mobile.svg';
import IconWeb from '../../images/landings/meldbilskade-icon-web.svg';
import Bg from '../../images/landings/meltbildskade_header.jpg';

class MeldBildskade extends Component {
  render() {
    return (
      <div className="meld">
        <div className="landing-header meld__header">
          <img src={Bg} alt=""/>
          <div className="meld__logo">
            <img src={Logo} alt="Meld Bilskade Logo" />
            <div>
              <h1>Meld Bilskade</h1>
              <p>Your car is as good as on the way to the yard.
                <br/>
                Hardly safe and easy.</p>
            </div>
          </div>
        </div>
        <div className="meld__block">
          <img className="meld__screen" src={WhitePhone} alt="Screen" align="left"/>
          <h2 className="meld__main-title">
            Stenstrop in the box?
            <span>Bulket back door?</span>
          </h2>
        </div>
          <p className="meld__text-italic">
            But do you have a list of all the different
            companies that can fix the car damage
            for you?
          </p>
          <div className="meld__text">
            <p className="meld__description">
              <span className="meld__description-title">Here's how it works:</span>
              Once the damage has occurred, send a complaint directly into the My Auto Damage
              app, and get the prices from the different companies right on the phone. Then you can
              choose which one suits you best - submit your order - choose a delivery date that suits
              you and see the estimated price and voila! <br/>
              Your car is as good as on the way to the yard. <br/>
              Hardly safe and easy.
            </p>
            <img className="meld__main-screen" src={RedPhone} alt="Screen" align="right"/>
          </div>
        <div className="meld__wrapper-bg">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span></h3>
          <div className="landing-wrapper">
            <div className="meld__technologies-wrapper">
              <div className="meld__technologies">
                <img src={IconMob} alt="MOBILE"/>
                <div className="technologies">
                  <span className="technology">Objective C</span>
                  <span className="technology">Java</span>
                  <span className="technology">AFNetworking</span>
                  <span className="technology">OpenCV</span>
                  <span className="technology">Realm</span>
                  <span className="technology">Firebase Cloud Messaging</span>
                </div>
              </div>
              <div className="meld__technologies">
                <img src={IconWeb} alt="WEB"/>
                <div className="technologies">
                  <span className="technology">.NET</span>
                  <span className="technology">C#</span>
                  <span className="technology">ASP.NET MVC</span>
                  <span className="technology">jQuery</span>
                  <span className="technology">SQL Server</span>
                  <span className="technology">LINQ to SQL</span>
                  <span className="technology">Google Maps API</span>
                  <span className="technology">SignalR</span>
                  <span className="technology">MSSQL</span>
                  <span className="technology">LOG4NET</span>
                </div>
              </div>
            </div>
            <div className="landing-team">
              <img src={Team} alt="Team" loading="lazy"/>
            </div>
          </div>
        </div>
        <div className="landing-info isBlack">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Jan Harald</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2016 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="http://meldbilskade.no" target="_blank">meldbilskade.no</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MeldBildskade;
