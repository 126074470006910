/* eslint-disable */
import React, { Component } from 'react';
import BlirFint from "../../Landings/BlirFint/BlirFint";
import Charlie from "../../Landings/Charlie/Charlie";
import Eduspike from "../../Landings/Eduspike/Eduspike";
import Foodlist from "../../Landings/Foodlist/Foodlist";
import Getwell from "../../Landings/Getwell/Getwell";
import Gratify from "../../Landings/Gratify/Gratify";
import IkidDo from "../../Landings/IkidDo/IkidDo";
import Kam from "../../Landings/Kam/Kam";
import KarriereStart from "../../Landings/KarriereStart/KarriereStart";
import MeldBildskade from "../../Landings/MeldBildskade/MeldBildskade";
import ParrotPlay from "../../Landings/ParrotPlay/ParrotPlay";
import Seequre from "../../Landings/Seequre/Seequre";
import ThirtyPills from "../../Landings/ThirtyPills/ThirtyPills";
import Vango from "../../Landings/Vango/Vango";
import Sensee from "../../Landings/Sensee/Sensee";

class WorksItem extends Component {

  render() {
    const components = {
      '30-pills': ThirtyPills,
      'van-go': Vango,
      'blir-fint': BlirFint,
      'ikiddo': IkidDo,
      'meld-bildskade': MeldBildskade,
      'karriere-start': KarriereStart,
      'kam': Kam,
      'foodlist': Foodlist,
      'parrotplay': ParrotPlay,
      'gratify': Gratify,
      'seequre': Seequre,
      'eduspike': Eduspike,
      'charlie': Charlie,
      'getwell': Getwell,
      'sensee': Sensee
    };

    // console.log('ololo ->', this.props.match);
    // TODO Add Error component
    const DynamicComponent = components[this.props.match.params.name] || 'Oops';

    return (
      <DynamicComponent />
    );
  }
}

export default WorksItem;
