/* eslint-disable */
import React, { Component } from 'react';
import star from '../../images/ServiceDetailsIcons/star.svg';

export default class CommentCarouselItem extends Component {
  render() {
    const { data } = this.props;
    const image = data.image && require(`../../images/clientPhoto/${data.image}.svg`);

    return (
      <div className="comment-carousel-item">
        <div className="item-header">
          <div className="item-stars">
            <img src={star} className="item-star" alt="" />
            <img src={star} className="item-star" alt="" />
            <img src={star} className="item-star" alt="" />
            <img src={star} className="item-star" alt="" />
            <img src={star} className="item-star" alt="" />
          </div>
          <span className="item-date"> {data.date} </span>
        </div>
        <div className="item-text-container">
          <span className="text-header">{data.title}</span>
          <p className="item-text">{data.description}</p>
        </div>
        <div className="item-footer">
          <img className="company-logo" src={image} alt="" />

          <div className="comment-author-info">
            <span className="author-name">{data.name}</span>
            <span className="author-position">{data.position}</span>
            <span className="author-country">{data.city}</span>
          </div>
        </div>
      </div>
    );
    // return (
    //   <div className='comment-carousel-item'>
    //     <div className='comment-carousel-item__img'>
    //       <img src={image} alt="" className=''/>
    //     </div>
    //     <div className='comment-carousel-item__comment'>
    //       <h5 className="comment-carousel-item__title">
    //         {data.title}
    //       </h5>
    //       <p className="comment-carousel-item__description">
    //         {data.description}
    //       </p>
    //       <span className='comment-carousel-item__author'>{data.name}</span>
    //       <span className='comment-carousel-item__city'>{data.city}</span>
    //     </div>
    //   </div>
    // )
  }
}
