/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Mac from '../../images/landings/parrotplay_mac.png';
import Logo from '../../images/landings/pp-logo.tv-2.png';
import Screen from '../../images/landings/pc_home.png';
import Team from '../../images/landings/parrotplay_team.png';
import IconWeb from '../../images/landings/parrot-icon-web.svg';

class ParrotPlay extends Component {
  render() {
    return (
      <div className="parrot">
        <div className="landing-header parrot__header">
          <div className="parrot__header-logo">
            <img className="parrot-logo" src={Logo} alt="ParrotPlay"/>
            <p>All your power services in one place</p>
            <img className="" src={Mac} alt="ParrotPlay"/>
          </div>
        </div>
        <div className="parrot__main">
          <div className="parrot__main-block">
            <img src={Screen} alt="ParrotPlay" />
            <p className="parrot__text">
              ParrotPlay is a social and personal streaming
              and TV guide provided as a native component
              to online media companies.
            </p>
          </div>
          <p className="parrot__text-center">
            This enables our partners to offer a single access point to all
            entertainment provided by premium streaming services.
          </p>
        </div>
        <div className="bg-wrapper">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span></h3>
          <div className="landing-team">
            <img src={Team} alt="Team" />
          </div>
          <div className="bg-inner">
            <div className="parrot__technologies-wrapper">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies">
                <span className="technology">TypeScript</span>
                <span className="technology">Angular 2</span>
                <span className="technology">Node.js</span>
                <span className="technology">Socket.IO</span>
                <span className="technology">MongoDB</span>
                <span className="technology">Neo4j</span>
              </div>
            </div>
            <div className="landing-info">
              <table>
                <tbody>
                <tr>
                  <th>Client:</th>
                  <td>Hein Espen</td>
                </tr>
                <tr>
                  <th>Project was started:</th>
                  <td>from 2015 to now</td>
                </tr>
                <tr>
                  <th>Link:</th>
                  <td><a href="https://www.parrotplay.tv" target="_blank">parrotplay.tv</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ParrotPlay;
