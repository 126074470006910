/* eslint-disable */
import React, { Component, Fragment } from 'react'
import TeamAugmentation from '../Components/Services/TeamAugmentation/TeamAugmentation';
import { ServiceUrls, ServiceContent } from '../Mock';
import WebDevelopment from "../Components/Services/WebDevelopment/WebDevelopment";
import IotDevelopment from "../Components/Services/IotDevelopment/IotDevelopment";
import MobileDevelopment from "../Components/Services/MobileDevelopment/MobileDevelopment";
import EnterpriseDevelopment from "../Components/Services/EnterpriseDevelopment/EnterpriseDevelopment";
import ChatbotDevelopment from "../Components/Services/ChatbotDevelopment/ChatbotDevelopment";
import Consulting from "../Components/Services/Consulting/Consulting";
import ServicesContent from "./ServicesContent";
import { ServiceDetailsData } from '../Mock/serviceDetails';
import ServiceDetails from '../Components/Services/ServiceDetails';

export default class Service extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    // console.log('did mount =>', this.props.match);
    // let id = this.props.match;
  }
  renderServicePage = () => {
    let id = this.props.match.params.id;
    return (
    <Fragment>
      <ServiceDetails pageID={id}/>
      <ServicesContent data={ServiceContent[id]}/>
    </Fragment>
    )
    // if (id === ServiceUrls.TeamAugmentation) {
    //   console.log('renderServicePage =>', ServiceContent[ServiceUrls.TeamAugmentation].projects);
    //   return (
    //     <Fragment>
    //       <TeamAugmentation />
    //       <ServicesContent data={ServiceContent[ServiceUrls.TeamAugmentation]}/>
    //     </Fragment>
    //   )
    // }
    //   if (id === ServiceUrls.WebDevelopment) {
    //   return (
    //     <Fragment>
    //       <WebDevelopment />
    //       <ServicesContent data={ServiceContent[ServiceUrls.WebDevelopment]}/>
    //     </Fragment>
    //   )
    // }
    //   if (id === ServiceUrls.IoTAppsDevelopment) {
    //   return (
    //     <Fragment>
    //       <IotDevelopment />
    //       <ServicesContent data={ServiceContent[ServiceUrls.IoTAppsDevelopment]}/>
    //     </Fragment>
    //   )
    // }
    //   if (id === ServiceUrls.MobileAppsDevelopment) {
    //   return (
    //     <Fragment>
    //       <MobileDevelopment />
    //       <ServicesContent data={ServiceContent[ServiceUrls.MobileAppsDevelopment]}/>
    //     </Fragment>
    //   )
    // }
    //   if (id === ServiceUrls.EnterpriseAppsDevelopment) {
    //   return (
    //     <Fragment>
    //       <EnterpriseDevelopment />
    //       <ServicesContent data={ServiceContent[ServiceUrls.EnterpriseAppsDevelopment]}/>
    //     </Fragment>
    //   )
    // }
    // if (id === ServiceUrls.ChatbotDevelopment) {
    //   return (
    //     <Fragment>
    //       <ChatbotDevelopment />
    //       <ServicesContent data={ServiceContent[ServiceUrls.ChatbotDevelopment]}/>
    //     </Fragment>
    //   )
    // }
    // if (id === ServiceUrls.Consulting) {
    //   return (
    //     <Fragment>
    //       <Consulting />
    //       <ServicesContent data={ServiceContent[ServiceUrls.Consulting]}/>
    //     </Fragment>
    //   )
    // }
  };

  render() {
    return (
      <div className='service-page'>
        {this.renderServicePage()}
      </div>
    )
  }
}
