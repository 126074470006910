/* eslint-disable */
import React, { Component } from "react";
import TeamArrow from "../images/team-arrow.svg";
import "./style.scss";
import Column from "../Components/Team/Column";
import { GetTeam } from "../Mock";
import Hammer from "react-hammerjs";

const cloneDeep = require("clone-deep");
var classNames = require("classnames");

export const PointerDirection = {
  Right: "Right",
  Left: "Left"
};
export default class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollPosition: 0,
      isPersonSelect: false,
      team: [],
      infoPointerDirection: null,
      selectedPerson: {},
      startX: 0,
      endX: 0,
      isPanning: false
    };
  }

  componentDidMount() {
    GetTeam().then(data => {
      let team = data.map(item => {
        return item.team.map(i => {
          i.isSelected = false;
          return i;
        });
      });
      this.setState({ team });
    });

    // onPersonClick handle when scrolling team
    document.getElementById("team-container").addEventListener("click", e => {
      if(this.state.isPanning) {
        e.stopPropagation();
        e.preventDefault();
      }
    })

    document.getElementById("team-container").addEventListener("keydown", this.onKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeydown);
  }

  handlePanStart = e => {
    this.setState({ startX: e.deltaX, animateSlide: false, isPanning: true })
  };

  handlePanEnd = e => {
    this.setState({ endX: Math.abs(this.state.scrollPosition) })
    setTimeout(() => {
      this.setState({ isPanning: false })
    }, 300);
  };
  
  handlePan = e => this.onMove(null, true, e);

  onMove = (isLeft, isTouch, event) => {
    const container = document.getElementById("team-container");
    const containerWidth = container.offsetWidth;
    const containerOffset = container.scrollWidth - containerWidth;
    const { scrollPosition } = this.state;
    let pos = 0;

    if (isTouch) {
      const newX = event.deltaX;
      pos = -(this.state.startX + this.state.endX - newX);
    } else if (isLeft != null) {
      pos = isLeft ? scrollPosition + containerWidth / 2 : scrollPosition - containerWidth / 2;
    } else {
      if (event.deltaX != null) {
        pos = scrollPosition + Math.ceil((event.deltaX * 3) / (event.deltaTime / 100));
      }
    }

    const newScrollPosition = pos > 0 ? 0 : Math.abs(pos) > containerOffset ? -containerOffset : pos;

    this.setState({
      scrollPosition: newScrollPosition,
      endX: !isTouch ? Math.abs(scrollPosition) : this.state.endX,
      animateSlide: !isTouch
    });
  };

  onKeydown = e => {
    switch (e.keyCode) {
      case 37:
        this.onMove(true, false, null);
        break;
      case 39:
        this.onMove(false, false, null);
        break;
    }
  };

  onPersonClick = (person, event) => {
    if (this.state.isPersonSelect) {
      this.unSelect(event);

      return;
    }

    const { team } = this.state;
    let elem = document.getElementById(`person-${person.id}`);
    let elemXPosition = elem.getBoundingClientRect().x + 150; // 150 Safe area
    let windowWidth = window.innerWidth;
    let infoPointerDirection = windowWidth - elemXPosition < 350 ? PointerDirection.Left : PointerDirection.Right; // 350 - info pointer width
    let newTeam = cloneDeep(team);
    newTeam.forEach(t => {
      let item = t.find(i => i.id === person.id);
      if (item) item.isSelected = true;
    });
    this.setState({ isPersonSelect: true, team: newTeam, infoPointerDirection, selectedPerson: person });
  };

  unSelect = event => {
    event.stopPropagation();
    event.preventDefault();
    const { team } = this.state;
    let newTeam = cloneDeep(team);
    newTeam.forEach(t => {
      t = t.map(i => {
        i.isSelected = false;
        return i;
      });
    });
    this.setState({ isPersonSelect: false, team: newTeam });
  };

  render() {
    const { isPersonSelect, team, selectedPerson, animateSlide } = this.state;
    const icon = selectedPerson.icon && require(`../images/teamIcons/${selectedPerson.icon}.png`);
    const mobilePersonalClasses = classNames({
      "mobile-personal-informations-hidden": true,
      "mobile-personal-informations-shown": isPersonSelect
    });
    const mobileWrapperrClasses = classNames({
      "mobile-personal-informations": true,
      "mobile-personal-informations-grow": isPersonSelect
    });
    const teamContainerClasses = classNames({
      "team-container": true,
      "team-container-animate": animateSlide
    });

    return (
      <div className="team-section-wrapper">
        <div className="team-header">
          <span className="team-header-text-left">
            Unicreo is a team of more than 40 superheroes, who are the fastest and the most creative people of all you may know. <br />
            Development is not easy, but we make it easy.
          </span>
          <span className="team-headre-text-right">TEAM</span>
        </div>
        <div className="team-section-content">
          <div className="arrow-container">
            <div onClick={() => this.onMove(true, false, null)}>
              <img src={TeamArrow} alt="" className="team-arrow-left" />
            </div>
            <div onClick={() => this.onMove(false, false, null)}>
              <img src={TeamArrow} alt="" className="team-arrow-right" />
            </div>
          </div>
          <Hammer onPanStart={this.handlePanStart} onPanEnd={this.handlePanEnd} onPan={this.handlePan}>
          <div className="team-items-wrapper">
            <div className="opacity-block">{isPersonSelect && <div className="isOpasity" onClick={this.unSelect}></div>}</div>
            <div>
              <div id="team-container" className={teamContainerClasses} ref="team" style={{ transform: `translate3d(${this.state.scrollPosition}px, 0, 0)` }}>
                {team.length !== 0 &&
                  team.map((item, index) => {
                    return <Column column={item} onPersonClick={this.onPersonClick} key={index} infoPointerDirection={this.state.infoPointerDirection} />;
                  })}
              </div>
            </div>
          </div>
          </Hammer>
        </div>
        <div className={mobileWrapperrClasses}>
          <div className={mobilePersonalClasses}>
            <div className="mobile-person-profile">
              <span className="person-name">{(selectedPerson.name || "").toUpperCase()}</span>
              <span className="person-position">{selectedPerson.position || ""}</span>
              <div className="person-nickname">
                <img src={icon && icon} alt="" className="person-nickname-icon" />
                <span className="person-nickname-text">{selectedPerson.nickname}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
