/* eslint-disable max-len */
import { ServiceUrls } from '.';
// WEB options
import webDataDriven from '../images/ServiceDetailsIcons/webDataDriven.svg';
import webHighPerfoming from '../images/ServiceDetailsIcons/webHighPerfoming.svg';
import webInteractive from '../images/ServiceDetailsIcons/webInteractive.svg';
import webResponsive from '../images/ServiceDetailsIcons/webResponsive.svg';
import webSecure from '../images/ServiceDetailsIcons/webSecure.svg';
import webScalable from '../images/ServiceDetailsIcons/webScalable.svg';
// MOBILE options
import mobNative from '../images/ServiceDetailsIcons/mobNative.svg';
import mobPixelPerfect from '../images/ServiceDetailsIcons/mobPixelPerfect.svg';
import mobAdvancedTech from '../images/ServiceDetailsIcons/mobAdvancedTech.svg';
import mobHybrid from '../images/ServiceDetailsIcons/mobHybrid.svg';
// IOT iptions
import iotCloud from '../images/ServiceDetailsIcons/iotCloud.svg';
import iotMobile from '../images/ServiceDetailsIcons/iotMobile.svg';
import iotSmart from '../images/ServiceDetailsIcons/iotSmart.svg';
import iotScalable from '../images/ServiceDetailsIcons/iotScalable.svg';
import iotSecure from '../images/ServiceDetailsIcons/iotSecure.svg';
import iotWeb from '../images/ServiceDetailsIcons/iotWeb.svg';
// BOT options
import botFace from '../images/ServiceDetailsIcons/botFace.svg';
import botFeedback from '../images/ServiceDetailsIcons/botFeedback.svg';
import botTailored from '../images/ServiceDetailsIcons/botTailored.svg';
import botIntegration from '../images/ServiceDetailsIcons/botIntegration.svg';
// TEAM options
import teamAgile from '../images/ServiceDetailsIcons/teamAgile.svg';
import teamAsemble from '../images/ServiceDetailsIcons/teamAsemble.svg';
import teamCulture from '../images/ServiceDetailsIcons/teamCulture.svg';
import teamFlexible from '../images/ServiceDetailsIcons/teamFlexible.svg';
import teamFullStack from '../images/ServiceDetailsIcons/teamFullStack.svg';
import teamPartnerShip from '../images/ServiceDetailsIcons/teamPartnerShip.svg';
// ENTERPRICE options
import entEnterprise from '../images/ServiceDetailsIcons/entEnterprise.svg';
import entIntegrations from '../images/ServiceDetailsIcons/entIntegrations.svg';
import entLegacy from '../images/ServiceDetailsIcons/entLegacy.svg';
import entMicroservice from '../images/ServiceDetailsIcons/entMicroservice.svg';
import entPlatforms from '../images/ServiceDetailsIcons/entPlatforms.svg';
import entScalability from '../images/ServiceDetailsIcons/entScalability.svg';
// CONSALTING options
import consBussines from '../images/ServiceDetailsIcons/consBussines.svg';
import consCode from '../images/ServiceDetailsIcons/consCode.svg';
import consInfrastructure from '../images/ServiceDetailsIcons/consInfrastructure.svg';
import consProject from '../images/ServiceDetailsIcons/consProject.svg';
import consProof from '../images/ServiceDetailsIcons/consProof.svg';
import consStart from '../images/ServiceDetailsIcons/consStart.svg';
// PAGE ICONS
import web from '../images/web.svg';
import mobile from '../images/mobile.svg';
import iot from '../images/iot.svg';
import bot from '../images/bot.svg';
import team from '../images/team.svg';
import enterprise from '../images/enterprise.svg';
import consulting from '../images/consulting.svg';

// eslint-disable-next-line import/prefer-default-export
export const ServiceDetailsData = {

  [ServiceUrls.WebDevelopment]: {
    pageName: 'WEB DEVELOPMENT',
    pageIcon: web,
    description: `We create simple, intuitive, and beautiful web applications for startups and enterprises.

    The right web app will help you strengthen your relationship with customers, scale your operations, and simply make money. At Unicreo, we know how to build you one. No matter where your users opened it – on a widescreen monitor, tablet, or an iPhone while waiting in line for a cup of coffee – they will fall in love with it at a glance.
    
    To craft you such an app, we take full care of it, from prototyping to delivery and support. Our partnership begins with a series of in-depth discussions. Together we establish a profound understanding of your needs and a clear vision of how to achieve them. 
    `,
    readMore: {
      firstColumn: `
      Then we build upon this knowledge mixing the latest web technologies and the best practices we learned throughout our decade-long history. 
      We will make sure that your web application is fast, secure, and scalable. Our engineers are skeptical and selective; 
      they will cherry-pick the only tools that perfectly fit the requirements of your app.
      `,
      secondColumn: `
      We know the value of communication, so you will be in the picture of what is going on with your application during the development. 
      You become a part of our team from the very first handshake.
      `,
    },
    options: [
      {
        icon: webScalable,
        title: 'Scalable',
        text: 'Ready for high-load and evolving functionality without compromising performance',
      },
      {
        icon: webDataDriven,
        title: 'Data-driven',
        text: 'Applications that inform, connect, and engage end-users',
      },
      {
        icon: webHighPerfoming,
        title: 'High-performing',
        text: 'Only meaningful interactions tailored to promote revenue',
      },
      {
        icon: webInteractive,
        title: 'Interactive and involving ',
        text: 'Responsive and intuitive interface. Well-designed user journey will absorb your users',
      },
      {
        icon: webSecure,
        title: 'Secure',
        text: 'Banking-industry grade security. Customers’ data protected under GDPR and standard regulations',
      },
      {
        icon: webResponsive,
        title: 'Responsive design',
        text: 'Beautifully looking app optimized for each and every device (well, almost) on the planet Earth',
      },
    ],
  },

  [ServiceUrls.MobileAppsDevelopment]: {
    pageName: 'MOBILE APPS DEVELOPMENT',
    pageIcon: mobile,
    description: `Let’s get your business at your clients’ fingertips. We will create a mobile application for them so smooth and responsive, they will adore it and will return to you again and again. 

    To make such an app, it will take your excellent business idea, cutting-edge technologies, and our team of professionals. They will wield these technologies to turn your idea into a unique mobile application that will bring your business right in front of your customers.
    `,
    readMore: {

      firstColumn: `
      At Unicreo, we provide full life-cycle of mobile application development. 
      We build native and hybrid apps for Apple iOS and Google Android so you can reach your clients and 
      stay closely connected with them wherever they are. 
      `,
      secondColumn: `
      Our UX designers, developers, and business analysts are eager to turn your ideas into the users’ favorite mobile experience.
      `,
    },
    options: [
      {
        icon: mobNative,
        title: 'Native apps',
        text: 'Top performance, the latest SDK features. Swift and Objective C for iOS, Kotlin and Java for Android',
      },
      {
        icon: mobPixelPerfect,
        title: 'Pixel-perfect implementation',
        text: 'We follow guidelines and UI specs rigorously',
      },
      {
        icon: mobHybrid,
        title: 'Hybrid apps',
        text: 'Experienced React Native, Ionic, and Flutter engineers. All platforms, the same codebase',
      },
      {
        icon: mobAdvancedTech,
        title: 'Advanced technologies',
        text: 'Take advantage of innovations with the use of AR, VR, beacons, and wearables',
      },
    ],
  },

  [ServiceUrls.IoTAppsDevelopment]: {
    pageName: 'IoT APPS DEVELOPMENT',
    pageIcon: iot,
    description: `The Internet of Things is all about connectivity, so we’ll help you to make as many connections as your business requires.

    Yes, IoT has its challenges. It can overwhelm, especially when it comes to high-speed data processing and heavy loads on your servers. After all, it is Big Data at its finest. To prosper in the market of connected things, you need a reliable tech buddy by your side. Someone with strong expertise in building secure back-end solutions able to process copious telemetry data. That’s why we’re here – to help you with sound infrastructure for your IoT system.
    
    At Unicreo, we deliver custom apps that help exploit the power of connectivity and place all the data your devices produce right into your hand. You’ll get all the precious insights about your customers, products, or machinery. 
    `,
    readMore: {
      firstColumn: `
      Our back-end solutions for endpoints will allow your application to safely communicate with devices of any kind through industry-standard and custom protocols.
      To secure the scalability of your business, we’ll help you seamlessly integrate your IoT architecture with top cloud services, like MS Azure, without downtime. No one will get an additional day off, sorry. And, yeah, we have got a few tricks up our sleeves that will help you save some money there.   
      `,
      secondColumn: `
      No one will get an additional day off, sorry. And, yeah, we have got a few tricks up our sleeves that will help you save some money there.  
      `,
    },
    options: [
      {
        icon: iotCloud,
        title: 'Cloud Services',
        text: 'Seamless integration with the top cloud services, i.e. MS Azure and AWS',
      },
      {
        icon: iotSmart,
        title: 'Smart data',
        text: 'Expertise in innovative technologies including AI, Machine learning, and Big data',
      },
      {
        icon: iotScalable,
        title: 'Scalable back-end',
        text: 'Focus on the right technology stack and scalable solutions',
      },
      {
        icon: iotMobile,
        title: 'Mobile applications',
        text: 'Precious insights from your connected devices in the palm of your hand',
      },
      {
        icon: iotSecure,
        title: 'Secure architecture',
        text: 'Threat modeling during the design stage to ensure safe maturity and data perimeter protection for your business',
      },
      {
        icon: iotWeb,
        title: 'Web front-end reporting',
        text: 'Intuitive analytics and control dashboards. With love from our UX designers right onto your screen',
      },
    ],
  },

  [ServiceUrls.ChatbotDevelopment]: {
    pageName: 'CHATBOT DEVELOPMENT',
    pageIcon: bot,
    description: `Chatbots have personalities. They can learn and teach, support and entertain, they can speak naturally. A properly made chatbot will help you reach your customers, engage them and even entertain. Wherever your clients are, with a smart chatbot, you’ll always be there for them. And you know what? We can make you one.

    Chatbots are not merely virtual buddies, but powerful assistants with disproportionately low maintenance costs. There’s some much they can do — handle your mission-critical tasks, automate busywork, accept payments, or harvest feedback from your clients.
    `,
    readMore: {
      firstColumn: `
      Together, our developers, data scientists, and business analysts provide full chatbot development  —  from idea to publishing and support. 
      We integrate chatbots into various business fields from e-commerce to corporate information exchange. 
      `,
      secondColumn: `
      Just tell us what you do and we’ll build a digital bridge between your business and your customers.
      `,
    },
    options: [
      {
        icon: botFace,
        title: 'Platforms of your choice',
        text: 'Including Facebook Messenger, WhatsApp, Slack, Viber, Telegram, you-name-it',
      },
      {
        icon: botIntegration,
        title: 'Complete integration',
        text: 'Channels, business tools, and back-end under one umbrella of communication',
      },
      {
        icon: botTailored,
        title: 'Tailored UX',
        text: 'Interaction driven by customer’s needs',
      },
      {
        icon: botFeedback,
        title: 'Feedback collection and analytics',
        text: 'Fruitful insights based on real-world historical data gathered from your customers',
      },
    ],
  },

  [ServiceUrls.TeamAugmentation]: {
    pageName: 'TEAM AUGMENTATION',
    pageIcon: team,
    description: `Hiring great engineers is expensive and time-consuming. Let Unicreo take this pain out for you. We will seamlessly integrate with your team bringing in years of expertise.

    At Unicreo, we never stop learning. We encourage our developers to expand and share their knowledge. By exchanging insights with colleagues and contributing to open source projects (respecting NDAs, of course), they deepen their experience and raise our company work standards. We are confident that they will seamlessly integrate with your team and help you to advance your project from initial planning to successful deployment. Last but not least, they are eager to help you.
    If your team needs skilled project managers, we have them too. 
    `,
    readMore: {
      firstColumn: `
      They are veterans in planning and managing projects of any complexity and they love to help you too. 
      In our working process, we rely on agile methodologies. We value daily communication with the client and full transparency of the development process. 
      `,
      secondColumn: `
      Our team is flexible as well. We stay focused on delivering an agreed subset of the product’s features, while always being ready for refining and re-prioritizing of product features.
      Oh, and we strive to build long-term relationships. Our software engineers have vast experience of working in multicultural distributed environments. Your values will become ours too.      
      `,
    },
    options: [
      {
        icon: teamFullStack,
        title: 'Full-stack team',
        text: 'Exceptional engineers, veteran bug-killers, and experienced project managers at your disposal',
      },
      {
        icon: teamPartnerShip,
        title: 'Partnership-inclined',
        text: 'Leverage our skills even more through long-term cooperation. Your success is ours too',
      },
      {
        icon: teamAsemble,
        title: 'Ready to assemble',
        text: 'Get a grander scale for your team quickly and painlessly',
      },
      {
        icon: teamCulture,
        title: 'Open to your culture',
        text: 'Flexible team with impeccable workspace ethics and respect for your culture',
      },
      {
        icon: teamFlexible,
        title: 'Flexible and accessible',
        text: 'Assign tasks, use convenient tools, get in touch and communicate with developers anytime',
      },
      {
        icon: teamAgile,
        title: 'Adepts of the agile approach',
        text: 'Get agreed features in time. Don’t hesitate to refine the overall product at any moment',
      },
    ],
  },

  [ServiceUrls.EnterpriseAppsDevelopment]: {
    pageName: 'ENTERPRISE APPS DEVELOPMENT',
    pageIcon: enterprise,
    description: `Take advantage of our decade-long expertise in enterprise software development, API integration, and legacy upgrades. At Unicreo, we build secure and fast applications that also feel good. 

    With over ten years of experience in business automation, we tailor industry-specific software that helps organizations reach their maximum. Depending on the company needs, your custom-made solution may include web, desktop, mobile applications, and ad hoc private cloud setup. We also integrate new technologies with current enterprise infrastructures and  upgrade legacy projects to keep businesses agile and relevant.
    
    Our work starts with determining the features and applications your enterprise will need for smooth functioning. Once we complete our work, you get yourself a profit tool. Future-proof, competitive, and ready-to-go.
    `,
    readMore: {},
    options: [
      {
        icon: entPlatforms,
        title: 'All platforms and screens',
        text: 'Web, desktop, and mobile apps powered by a cloud or private cloud backend',
      },
      {
        icon: entIntegrations,
        title: 'Integration with infrastructure',
        text: 'Data exchange across CRM, ERP, ECM, and communication channels',
      },
      {
        icon: entEnterprise,
        title: 'Enterprise-class security',
        text: 'Proactive protection using the best industry practices',
      },
      {
        icon: entMicroservice,
        title: 'Microservices',
        text: 'Scalable, agile, easy to maintain and change towards growing business demands',
      },
      {
        icon: entLegacy,
        title: 'Legacy project upgrades',
        text: 'Porting/re-engineering of old legacy systems to meet new business requirements',
      },
      {
        icon: entScalability,
        title: 'Scalability for success',
        text: 'Seamless integration with the most powerful Cloud Services',
      },
    ],
  },

  [ServiceUrls.Consulting]: {
    pageName: 'CONSULTING',
    pageIcon: consulting,
    description: `We’ve been here for a decade, gaining experience, accumulating expertise, growing together with our clients. We know pitfalls and challenges both in software and business development. Our team of consultants ready to dive deep into your core business domain, involving subject matter experts where needed. They summarize findings and help to establish the right custom strategy for product development.

    Our experts can define and oversee outsourcing activities for your application development project or provide you with strategic consulting on different aspects of the entire developing process. We’ll help you map out planning, budget, timeline estimation, scalability, risk management, and quality assessment.
    `,
    readMore: {},
    options: [
      {
        icon: consBussines,
        title: 'Business Analytics',
        text: 'Working hand-by-hand with your subject matter experts to extract insights that could lead to better business decisions',
      },
      {
        icon: consProject,
        title: 'Project rescue',
        text: 'Scanning ongoing projects, finding pitfalls, suggesting improvements',
      },
      {
        icon: consProof,
        title: 'Proof-of-concept evaluation',
        text: 'Evaluating your business hypothesis through estimated research or MVP',
      },
      {
        icon: consInfrastructure,
        title: 'Infrastructure evaluation',
        text: 'Advising on future-proof tools that fit your business goals best',
      },
      {
        icon: consCode,
        title: 'Code review',
        text: 'Testing the value and quality of the existing codebase against the industry standards',
      },
      {
        icon: consStart,
        title: 'Startup consulting',
        text: 'Full yet affordable technical business examination and road mapping for hockey stick growth',
      },
    ],
  },
};
