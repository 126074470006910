/* eslint-disable */
import React, { Component } from 'react';

export default class DropDownItem extends Component {
  render() {
    const { icon, description, onItemClick } = this.props;
    return (
      <div className="dropdown-item" onClick={onItemClick}>
        { icon && <img src={icon} alt="" className="dropdown-icon" /> }
        <span>{description}</span>
      </div>
    );
  }
}
