import React from 'react';
import './style.scss';


const ServiceDetailsItem = ({ icon, title, text }) => (
  <div className="service-item-container">
    <img src={icon} alt="" className="service-item-icon" />
    <span className="service-item-title">{title}</span>
    <p>{text}</p>
  </div>
);

export default ServiceDetailsItem;
