/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Item from './Item';

export default class Column extends Component {
  render() {
    const { column, onPersonClick} = this.props;
    if (column.length === 2) {
      return (
        <div className='column'>
          <Item person={column[0]} onPersonClick={onPersonClick} {...this.props}/>
          <Item person={column[1]} onPersonClick={onPersonClick} {...this.props}/>
        </div>
      )
    }

    if (column.length === 3) {
      return (
        <div className='column'>
          <Item person={column[0]} onPersonClick={onPersonClick} {...this.props}/>
          <Item person={column[1]} onPersonClick={onPersonClick} {...this.props}/>
          <Item person={column[2]} onPersonClick={onPersonClick} {...this.props}/>
        </div>
      )
    }
  }
}
