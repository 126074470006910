import React from 'react';
import './style.scss';

const classNames = require('classnames');

const ReadMoreBlock = ({ isShown, readMore, onReadMorelick }) => {
  const classes = classNames({
    'read-more': true,
    'read-more-shown': isShown,
  });
  return (
    <>
      {!isShown && (
        <div
          className="read-more-btn"
          onClick={onReadMorelick}
          onKeyDown={onReadMorelick}
          role="button"
          tabIndex="0"
        >
          <span>Read more</span>
        </div>
      ) }

      <div className={classes}>
        <p className="first-read-more-column">{readMore.firstColumn}</p>
        <div className="read-more-right-block">
          <p className="secons-read-more-column">{readMore.secondColumn}</p>
          <p className="empty-read-more-column" />
        </div>

      </div>
    </>

  );
};

export default ReadMoreBlock;
