/* eslint-disable */
import React, {Component} from 'react';
import './style.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import iKidDo from '../images/iKidDo.png';
import VangoIcon from '../images/vango.png';
import BlirFintIcon from '../images/blirFint.png';
import Bilskade from '../images/bilskade.png';
import CarrierStarter from '../images/karrierestart.png';
import Gratify from '../images/gratify.png';
import ParrotPlay from '../images/parrotPlay.png';
import EduSpike from '../images/eduspike.png';
import Pils from '../images/pils.png';
import Seequre from '../images/seequre.png';
import Charlie from '../images/charlie.png';
import GetWell from '../images/getwell.png';
import Kam from '../images/kam.png';
import Foodlist from '../images/foodlist.png';
import CarousesHeader from '../Components/Carousel/CarousesHeader';
import CarouselItem from '../Components/Carousel/CarouselItem';
import { ContactUsContext } from '../App'

export default class CarouselContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFirst: true,
      isLast: false,
      currIndex: 0,
    };
  }

  onNext = () => {
    this.carousel.onClickNext();
  };

  onPrev = () => {
    this.carousel.onClickPrev();
  };

  render() {
    return (
      <ContactUsContext.Consumer>
        {({state, onChangeSlide}) => {
          const settings = {
            showThumbs: false,
            showArrows: false,
            useKeyboardArrows: true,
            showIndicators: false,
            showStatus: false,
            emulateTouch: true,
            infiniteLoop: true,
            selectedItem: state.selectedProject.currIndex,
          };

          return (
            <div className="carousel-container">
              <CarousesHeader
                onNext={this.onNext}
                onPrev={this.onPrev}
                isFirst={state.selectedProject.isFirst}
                isLast={state.selectedProject.isLast}
                projectName={projects[state.selectedProject.currIndex].name}
                projectDate={projects[state.selectedProject.currIndex].date}
              />
              <Carousel {...settings} ref={ref => this.carousel = ref} onChange={index => onChangeSlide(index, projects.length)}>
                {projects.map(p => (
                  <CarouselItem key={p.id} description={p.description} image={p.img} url={p.url}/>
                ))}
              </Carousel>
            </div>
          )
        }}
      </ContactUsContext.Consumer>
    );
  }
}


const projects = [
  {
    id: 0,
    description: `
    Rides for your preteens & teens, when and where you need them. We do the vetting, so you don't have to. Our team of carefully vetted drivers are nannies, teachers, and babysitters. All of our drivers have at least three years of childcare experience, and are carefully vetted by our team via family references, background + driving record checks, and vehicle inspections.
    Recurring rides, one-off rides, last-minute rides...  you can post any ride, any time. Specify where and when you want your teen to be picked up. Your request goes out to our trusted, vetted pool of drivers. Once a driver accepts your request, you get alerted, and are able to see their full profile.
    `,
    img: VangoIcon,
    name: 'VANGO',
    date: '2018',
    url: 'van-go'
  },
  {
    id: 1,
    description: `
Sometimes it is urgent to get skilled professionals. Download Blir Fint and get an overview of all available craftsmen near you!

• Posting a job is free and non-binding
• Easy communication via mobile
• By using our chat, you do not have to communicate via your own private channels.
• Secure payment. We handle your payments and "freeze" the money until you are happy with the job!

Get yourself a craftsman today!
    `,
    img: BlirFintIcon,
    name: 'BLIR FINT',
    date: '2016 - NOW',
    url: 'blir-fint'
  },
  {
    id: 2,
    description: `
  iKidDo is the social app which allows You to bring order to your home tasks. Interactive task manager helps you to interest your kids in doing housework. 
  You can simply create the family, add members there and assign tasks to any of them.
    `,
    img: iKidDo,
    name: 'IKIDDO',
    date: '2018 - NOW',
    url: 'ikiddo'
  },
  {
    id: 3,
    description: `
Here's how it works: 
Once the damage has occurred, send a complaint directly into the My Auto Damage app, and get the prices from the different companies right on the phone. Then you can choose which one suits you best - submit your order - choose a delivery date that suits you and see the estimated price and voila! Your car is as good as on the way to the yard.
Hardly safe and easy.
    `,
    img: Bilskade,
    name: 'MELD BILSKADE',
    date: '2016 - NOW',
    url: 'meld-bildskade'
  },
  {
    id: 4,
    description: `
    KarriereStart collects and structures all the information about the job market, industries, occupations and employers throughout Norway. 
    System helps to search for the most suitablecandidates for the position, plus it searches jobs that match desires and experience. One of the features of this professional social networking website is extended tools for analysts. Analyze with specified criteria shows the compatibility of the candidate with the vacancy.
    `,
    img: CarrierStarter,
    name: 'KARRIERESTART.NO',
    date: '2011 - NOW',
    url: 'karriere-start'
  },
  {
    id: 5,
    description: `
Gratify is Slack add-on that puts you in touch with technical experts 24/7. 
Meet, hire and pay expert freelancers without leaving Slack
    `,
    img: Gratify,
    name: 'GRATIFY',
    date: '2016 - NOW',
    url: 'gratify'
  },
  {
    id: 6,
    description: `
ParrotPlay is a social and personal streaming and TV guide provided as a native component to online media companies.
    `,
    img: ParrotPlay,
    name: 'PARROTPLAY',
    date: '2015 - NOW',
    url: 'parrotplay'
  },
  {
    id: 7,
    description: `
    Education is leaving the auditorium and exploring the digital space. Future learning will be a mix of physical lectures and online studies.This calls for new ways to make students and teachers interact, collaborate and engage.
    Eduspike rewards positive learning behavior and students earn Spikes and get real life benefits.
    `,
    img: EduSpike,
    name: 'EDUSPUKE',
    date: '2016 - NOW',
    url: 'eduspike'
  },
  {
    id: 8,
    description: `
A medical app allows patients to keep track of taking medications and reminds to do this.
    `,
    img: Pils,
    name: '30 PILLS',
    date: '2017 - NOW',
    url: '30-pills'
  },
  {
    id: 9,
    description: `
    Primary purpose of the product is to provide functionality for secure video communication and instant messaging for small business companies. Essential part is to provide tight integration with MDaemon mail server
    `,
    img: Seequre,
    name: 'SEEQURE',
    date: '2016 - NOW',
    url: 'seequre'
  },
  {
    id: 10,
    description: `
    Marketplace supporting and promoting highly curated emerging& established brands that have high quality of products backed by incredibly talented designers!
    `,
    img: Charlie,
    name: 'CHARLIE!',
    date: '2016 - 2017',
    url: 'charlie'
  },
  {
    id: 11,
    description: `
    Marketplace supporting and promoting highly curated emerging& established brands that have high quality of products backed by incredibly talented designers!
    `,
    img: GetWell,
    name: 'GETWELL!',
    date: '2015 - 2016',
    url: 'getwell'
  },
  {
    id: 12,
    description: `
    KAM is an integrated solution supporting the entire process of promotional planning, control and evaluation.
    The aim of software is to help sales teams to set up trade promotion programs more efficiently and easier. Software has two modules: analysis and reporting. Reporting module allows printing and exporting promotional programs and technical documents in a variety of formats for internal use, for use by sales reps, for the retailer. Several layouts can be defined based on the retailer's or your internal requirements and any trade promotion program can be printed or exported into any desired format. Analysis module allows building handy lists and pivoting report tables showing aggregated sales and promotion information, selected using user-defined criteria and table layouts.
    `,
    img: Kam,
    name: 'KAM',
    date: '2017 - NOW',
    url: 'kam'
  },
  {
    id: 13,
    description: `
    Foodlist, a social application that allows people to buy products that are close to their end date of usage in Norwegian shops, was built from scratch . 
    With the help of Foodlist, users are able to share information about food that is in danger of becoming food waste . Read on to see how the product allowed shops to create their own lists of such products or products that were donated to the charity  while charity can negotiate with shops about products supply. 
    Save money, help the environment and support people in need!
    `,
    img: Foodlist,
    name: 'FOODLIST',
    date: '2016',
    url: 'foodlist'
  },
];
