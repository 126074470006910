/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import process from '../../images/approach-process.png';
import one from '../../images/one.png';
import two from '../../images/two.png';
import three from '../../images/3.png';
import four from '../../images/four.png';

export default class Approach extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    console.log('did mount =>', this.props.match)
    let id = this.props.match;
  }
  render() {
    return (
      <div className='approach-page approach'>
        <h5 className="approach__title">
          <img src={process} alt=""/>PROCESS</h5>
        <h2 className="approach__title-2">Wondering how we <br/>
          turn ideas into an actual product?</h2>
        <div className="approach__step-1 step">
          <div className="step__img">
            <img src={one} alt=""/>
          </div>
          <div className="step__text">
            <h4>inception</h4>
            <span>Initiation | Planning | Design</span>
            <p>Once you've got a sufficient <strong>project description </strong>
              (sometimes the <strong>tweet-sized pitch</strong> is good enough to
              start), we're ready to hop on the process of
              planning your project from scratch. Since
              defining an accurate vision of the final
              product is crucial here, we conduct several
              <strong> detailed discussions</strong> with the team and the client.</p>
            <p>Our team collects <strong>user stories</strong> and defines
              <strong> acceptance criteria</strong> to make sure we won't
              miss any scenario. Once the functionality
              of future software is planned, we start
              working on the <strong>prototype</strong> and <strong>user interface.</strong></p>
            <p>After brainstorming and analyzing all of the
              requirements, we can come up with a cost-effective
              solution tailored to your business.</p>
          </div>
        </div>
        <div className="approach__step-2 step">
          <div className="step__img">
            <img src={two} alt=""/>
          </div>
          <div className="step__text">
            <h4>implementation</h4>
            <p>Implementation is the most critical
               software development lifecycle phase,
              and we make sure to involve Unicreo's
              best brains into your product development.
              Based on the size of the project, your
              <strong> dedicated team</strong> will consist of a project
              manager, several developers, designer,
              and quality assurance specialist.
            </p>
            <p>In most cases, we use an <strong>agile approach.</strong>
              We accomplish development in iterations,
              known as sprints, during which we complete
              specific pieces of work. You will be able
              to <strong>review</strong> the results of development at
              the end of each sprint, which usually
              takes 1-2 weeks. The output of this stage
              is <strong>functional and testable software</strong></p>
          </div>
        </div>
        <div className="approach__step-3 step">
          <div className="step__img">
            <img src={three} alt="" className="left"/>
          </div>
          <div className="step__text">
            <h4>acceptance</h4>
            <p>Once our QA engineers have completed
              rigorous testing and quality assurance
              of the product, we <strong>deploy</strong> it to the
              <strong> production environment.</strong> At this point,
              you'll be able to examine the whole
              application along with developers to
              make sure it works right as expected.</p>
          </div>
        </div>
        <div className="approach__step-4 step">
          <div className="step__img">
            <img src={four} alt=""/>
          </div>
          <div className="step__text">
            <h4 className="">support</h4>
            <p><strong>Support</strong> and <strong>maintenance</strong> are the
              final stages where systems <strong>upgrades </strong>
              based on further users' feedback.
              Also, at this stage, we can take
              care of new requirements which
              may come up as a result of business growth.
            </p>
            <p>Maintenance services are available
              for any period determined by you.</p>
          </div>
        </div>
      </div>
    )
  }
}
