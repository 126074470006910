/* eslint-disable */
import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Redirect, withRouter } from "react-router-dom";
import Home from './Home';
import Service from './Containers/Service';
import Approach from './Containers/Approach/Approach';
import Header from './Containers/Header/Header';
import Works from './Components/Works/Works';
import AboutUs from './Containers/AboutUs/AboutUs';
import WorksItem from './Components/Works/WorksItem';
import ContactUsModal from './Components/ContactUsModal';
import ModalForm from "./Components/ModalForm/ModalForm";
import ScrollToTop from './Components/scrollToTop';
import Footer from './Components/Footer/Footer';

export const ContactUsContext = React.createContext({});

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showContactUsModal: false,
      isContactUsLink: false,
      isMobileMenuShown: false,
      selectedProject: {
        currIndex: 0,
        isLast: false,
        isFirst: true,
      },
    }
  }

  handleMobileMenuSwitch = (bool) => () => {
    this.setState({ isMobileMenuShown: bool })
  };

  onChangeSlide = (index, projectsLength) => {
    this.setState(() => ({
      selectedProject: {
        currIndex: index,
        isLast: index === projectsLength - 1,
        isFirst: index === 0,
      },
    }));
  };

  handleContactUsModalOpen = () => {
    if (document.documentElement.clientWidth < 768) {
      this.props.history.push('/contact-us');
      this.handleMobileMenuSwitch(false)();
    } else {
      this.setState({
        showContactUsModal: true
      })
    }
  };

  handleContactUsCloseModal = () => {
    this.setState({showContactUsModal: false})
  };

  render() {
    const { state, handleContactUsModalOpen, handleContactUsCloseModal, handleMobileMenuSwitch, onChangeSlide } = this;

    return (
      <ContactUsContext.Provider value={{state, handleContactUsModalOpen, handleContactUsCloseModal, handleMobileMenuSwitch, onChangeSlide}}>
        <ScrollToTop>
          <ContactUsModal/>
          <Header/>
          <Route exact path="/" component={Home}/>
          <Route path="/services/:id" component={Service}/>
          <Route path="/approach/:id" component={Approach}/>
          <Route exact path="/works/:name" component={WorksItem}/>
          <Route exact path="/works" component={Works}/>
          <Route path="/about-us" component={AboutUs}/>
          <Route path="/contact-us" component={ModalForm}/>
          <Footer/>
        </ScrollToTop>
      </ContactUsContext.Provider>
    );
  }
}

export default withRouter(App);
