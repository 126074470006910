/* eslint-disable */
import React, { Component } from 'react'
import './style.scss';
import Tabs from '../../Components/Tabs/Tabs';
import Logo from '../../images/unicreo_logo.png';
import { withRouter } from "react-router-dom";
import { ContactUsContext } from "../../App";

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isHomePage: false
    }
  }

  goToHome = (handleMobileMenuSwitch) => {
    const { history } = this.props;
    handleMobileMenuSwitch(false)()
    this.setState({ isHomePage: true }, () => {
      history.push(`/`);
      this.setState({ isHomePage: false })
    })
  };

  render() {
    const { isHomePage } = this.state;

    const modalCalsses = {
      modal: 'contact-us-modal',
      closeButton: 'modal-close-btn',
      overlay: 'modal-overlay'
    };

    return (
      <header className="App-header">
        <ContactUsContext.Consumer>
          {({ state, handleMobileMenuSwitch }) => (
            <>
              <div className="header-left-logo" onClick={() => this.goToHome(handleMobileMenuSwitch)}>
                <img src={Logo} alt="Unicreo" className="logo" />
              </div>
              <div className={!state.isMobileMenuShown ? 'nav-icon' : 'checked-nav-icon'}
                onClick={handleMobileMenuSwitch(!state.isMobileMenuShown)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Tabs isHomePage={isHomePage} />
            </>
          )}
        </ContactUsContext.Consumer>
      </header>
    )
  }
}
export default withRouter(Header)
