/* eslint-disable */
import React, { Component } from 'react';
import iot from '../../../images/iot.svg';
import bg from '../../../images/rectangle-bg.jpg';
import lines from '../../../images/lines.svg';
import './style.scss';
import {ContactUsContext} from "../../../App";

export default class IotDevelopment extends Component {
  render() {
    return (
      <div className="service-item">
        <div className="rectangle-bg">
          <img src={bg} alt="rectangle-bg"/>
        </div>
        <div className="container-top isIoT">
          <img className="container-top__lines" src={lines} alt=""/>
          <h2 className="container-top__title">
            <img src={iot} alt="Icon IoT"/>
            IoT APPS DEVELOPMENT
          </h2>
          <p>
            Keeping up with new technologies is a must for our modern world,
            but it'not easy when it comes to IoT devices. IoT solutions
            involve high-speed data analysis and processing, the possibility
            of heavy load and rapid data load to the server.
            For IoT devices retaining you need a top-notch
            tech buddy by your side, with strong expertise
            in building custom back-end solutions that are
            high-performing, secure and can process large
            amounts of telemetry data.
          </p>
          <p>
            We're here to help you with infrastructure
            solution for your IoT systems. Our range of
            services includes software development,
            prototype building and back-end development
            of such IoT platforms like proximity, infrared,
            thermal sensors, appliances and cameras that
            are connected by Wi-Fi or cellular networks,
            sending telemetry using MQTT, AMQP, CoAP or any other,
            even custom, protocols.
          </p>
          <p>
            We provide simple and fast data transfer across
            multiple sensors. As a result, you get the correct
            data display and fully digital connectivity.
          </p>
          <ContactUsContext.Consumer>
            {({ handleContactUsModalOpen }) => (
              <a onClick={handleContactUsModalOpen} className="link-contact-us">
                Are you ready to enhance data-driven decisions?</a>
            )}
          </ContactUsContext.Consumer>
        </div>
      </div>
    );
  }
}
