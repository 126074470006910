/* eslint-disable */
import Team from '../images/team.svg';
import Web from '../images/web.svg';
import Enterprise from '../images/enterprise.svg';
import Mobile from '../images/mobile.svg';
import Consalting from '../images/consulting.svg';
import Bot from '../images/bot.svg';
import Iot from '../images/iot.svg';
import Process from '../images/approach-process.svg';
import Soft from '../images/approach-software.svg';
import ApproachTeam from '../images/approach-team.svg';

export const ListType = {
  Services: 'Services',
  Approach: 'Approach'
};

export const ServiceUrls = {
  TeamAugmentation: 'team-augmentation',
  WebDevelopment: 'web-development',
  EnterpriseAppsDevelopment: 'enterprise-apps-development',
  MobileAppsDevelopment: 'mobile-apps-development',
  IoTAppsDevelopment: 'iot-apps-development',
  ChatbotDevelopment: 'chatbot-development',
  Consulting: 'consulting'
};

export const ApproachUrls = {
  SoftwareDevelopment: 'software-development',
  DedicatedDevelopmentTeam: 'dedicated-development-team',
  Process: 'process'
};

export const TabsUrsl = {
  Sevices: 'service',
  Works: 'works',
  Approach: 'approach',
  AboutUs: 'about-us'
};

export const ServicesItems = [
  {
    id: 0,
    icon: Team,
    description: 'Team Augmentation',
    type: ListType.Services,
    url: ServiceUrls.TeamAugmentation
  },
  {
    id: 1,
    icon: Web,
    description: 'Web Development',
    type: ListType.Services,
    url: ServiceUrls.WebDevelopment
  },
  {
    id: 2,
    icon: Enterprise,
    description: 'Enterprise Apps Development',
    type: ListType.Services,
    url: ServiceUrls.EnterpriseAppsDevelopment
  },
  {
    id: 3,
    icon: Mobile,
    description: 'Mobile Apps Development',
    type: ListType.Services,
    url: ServiceUrls.MobileAppsDevelopment
  },
  {
    id: 4,
    icon: Iot,
    description: 'IoT Apps Development',
    type: ListType.Services,
    url: ServiceUrls.IoTAppsDevelopment
  },
  {
    id: 5,
    icon: Bot,
    description: 'Chatbot Development',
    type: ListType.Services,
    url: ServiceUrls.ChatbotDevelopment
  },
  {
    id: 6,
    icon: Consalting,
    description: 'Consulting',
    type: ListType.Services,
    url: ServiceUrls.Consulting
  }
];

export const ApproachItems = [
  // {
  //   id: 0,
  //   icon: Process,
  //   description: 'Software Development',
  //   type: ListType.Approach,
  //   url: ApproachUrls.SoftwareDevelopment
  // },
  // {
  //   id: 1,
  //   icon: Soft,
  //   description: 'Dedicated Development Team',
  //   type: ListType.Approach,
  //   url: ApproachUrls.DedicatedDevelopmentTeam
  // },
  {
    id: 0,
    icon: ApproachTeam,
    description: 'Process',
    type: ListType.Approach,
    url: ApproachUrls.Process
  }
];

let TeamData = [
  {
    team: [
      { id: 0, photo: 'Ann', nickname: 'Angular, Node JS  supergirl', name: 'Ann', icon: 'angular', position: 'Full Stack Developer' },
      { id: 1, photo: 'Alena', nickname: 'invoicing Lakshmi', name: 'Alena', icon: 'Alena', position: 'CFO' }
    ]
  },
  {
    team: [
      { id: 3, photo: 'Alex', nickname: 'octopus', name: 'Alex', icon: 'octopus', position: 'CEO' },
      { id: 4, photo: 'Andry', nickname: 'android, java jedi', name: 'Andriy', icon: 'android', position: 'Android Developer' },
      { id: 5, photo: 'Anton', nickname: 'c-shark, .net wizard', name: 'Anton', icon: 'dot_net', position: 'Backend Developer' }
    ]
  },
  {
    team: [
      { id: 6, photo: 'Ed', nickname: 'react native, react ninja', name: 'Ed', icon: 'react', position: 'Frontend Developer' },
      { id: 7, photo: 'Elen', nickname: 'ui/ux speed minimalist', name: 'Elen', icon: 'Elen', position: 'UI/UX' }]
  },
  {
    team: [
      { id: 8, photo: 'Ira', nickname: '.net sungirl', name: 'Ira', icon: 'dot_net', position: 'Backend Developer' },
      { id: 9, photo: 'Juliya', nickname: 'QA Fairy', name: 'Juliya', icon: 'QA', position: 'Quality Assurance Engineer' },
      { id: 10, photo: 'Kolya', nickname: 'chief hacker', name: 'Nick', icon: 'Nick', position: 'CTO' }
    ]
  },
  {
    team: [
      { id: 11, photo: 'Kosha', nickname: 'c-sharp, .net warlock', name: 'Dmitriy', icon: 'dot_net', position: 'Architect, Team Lead' },
      { id: 12, photo: 'Kostyk', nickname: 'support, QA fabler ', name: 'Alex', icon: 'QA', position: 'Quality Assurance Engineer' }
    ]
  },
  {
    team: [
      { id: 13, photo: 'Leo', nickname: 'Node JS & .net rockstar', name: 'Alex', icon: 'node_js', position: 'Full Stack Developer' },
      { id: 14, photo: 'Malyk', nickname: 'c-sharp. .net orchestraman', name: 'Alex', icon: 'dot_net', position: 'Full Stack Developer' },
      { id: 15, photo: 'Masha', nickname: 'unicorn project manager', name: 'Masha', icon: 'Masha', position: 'Project Manager' }
    ]
  },
  {
    team: [
      { id: 16, photo: 'Max', nickname: 'ios, swift chewbakka', name: 'Max', icon: 'ios', position: 'iOs Developer' },
      { id: 17, photo: 'Olya', nickname: 'cattiest project manager', name: 'Olga', icon: 'Olya', position: 'Project Manager' }
    ]
  },
  {
    team: [
      { id: 18, photo: 'Sasha', nickname: 'QA inspector gadget', name: 'Alex', icon: 'QA', position: 'Quality Assurance Manager' },
      { id: 19, photo: 'Serega', nickname: 'c-sharp, .net wizard', name: 'Sergey', icon: 'dot_net', position: 'Full Stack Developer' },
      { id: 20, photo: 'Stas', nickname: '.net sifu', name: 'Stas', icon: 'dot_net', position: 'Backend Developer' }
    ]
  },
  {
    team: [
      { id: 21, photo: 'Vadim', nickname: 'c-sharp beatboy', name: 'Vadim', icon: 'dot_net', position: 'Backend Developer' },
      { id: 22, photo: 'Vietal', nickname: 'react native, react monster', name: "Vietal'", icon: 'react', position: 'Frontend Developer' }
    ]
  },
  {
    team: [
      { id: 37, photo: 'Oleh', nickname: 'angular master', name: 'Oleg', icon: 'angular', position: 'Frontend Developer' },
      { id: 24, photo: 'Shurik', nickname: 'full stack padawan', name: 'Alex', icon: 'fullstack', position: 'Full Stack Developer' },
      { id: 25, photo: 'Vladislav', nickname: 'full stack knight', name: 'Vlad', icon: 'fullstack', position: 'Full Stack Developer' }
    ]
  },
  {
    team: [
      { id: 26, photo: 'Jenya', nickname: 'full stack rogue', name: 'Eugene', icon: 'fullstack', position: 'Full Stack Developer' },
      { id: 27, photo: 'Mitya', nickname: 'Mobile godzilla', name: 'Mitya', icon: 'godzilla', position: 'Mobile Developer' }
    ]
  },
  {
    team: [
      { id: 28, photo: 'Yura', nickname: 'strongest project manager supervillain', name: 'Yuriy', icon: 'Yura', position: 'Project Manager' },
      { id: 29, photo: 'Sasha_lil', nickname: 'full stack padawan', name: 'Alex', icon: 'fullstack', position: 'Full Stack Developer' },
      { id: 31, photo: 'Artem', nickname: '.net cavalier', name: 'Atrem', icon: 'dot_net', position: 'Backend Developer' }
    ]
  },
  {
    team: [
      { id: 32, photo: 'Genrikh', nickname: 'ios hero', name: 'Genrikh', icon: 'ios', position: 'iOs Developer' },
      { id: 33, photo: 'Jeka', nickname: 'ios sailor', name: 'Eugene', icon: 'ios', position: 'iOs Developer' }
    ]
  },
  {
    team: [
      { id: 34, photo: 'Kat', nickname: 'business queen', name: 'Kate', icon: 'Katya', position: 'Business Manager' },
      { id: 35, photo: 'Anthony', nickname: 'react padawan', name: 'Anton', icon: 'react', position: 'Frontend Developer' },
      { id: 38, photo: 'Oksana', nickname: 'QA princess', name: 'Oksana', icon: 'QA', position: 'Quality Assurance Engineer' }
    ]
  },
  {
    team: [
      { id: 39, photo: 'Denis', nickname: '.net maniac', name: 'Den', icon: 'dot_net', position: 'Backend Developer' },
      { id: 41, photo: 'Marchella', nickname: 'Baby Boss', name: 'Max', icon: 'Marchella', position: 'Head Of Business Development' }
    ]
  },
  {
    team: [
      { id: 42, photo: 'Oleh_cop', nickname: '.net cop', name: 'Oleg', icon: 'dot_net', position: 'Backend Developer' },
      { id: 43, photo: 'Misha', nickname: '.net paratrooper', name: 'Misha', icon: 'dot_net', position: 'Backend Developer' },
      { id: 44, photo: 'SashaBik', nickname: '.net superhero', name: 'Alex', icon: 'fullstack', position: 'Full stack developer' }
    ]
  },
  {
    team: [
      { id: 45, photo: 'Ksusha', nickname: 'digital marketing birdie', name: 'Oksana', icon: 'parkhomenko', position: 'Digital Marketer/SMM' },
      {}
    ]
  },
];

export const GetTeam = () => {
  return new Promise((res, rej) => {
    res(TeamData)
  })
};

export const ServiceContent = {
  [ServiceUrls.TeamAugmentation]: {
    projects: [{
      id: 0,
      name: 'KAM',
      image: 'kam',
      date: '2017 - NOW',
      description: 'Campaign promotion enterprise app',
      url: 'kam'
    },
    {
      id: 1,
      name: 'Gratify',
      image: 'gratify',
      date: '2016 - NOW',
      description: 'Campaign promotion enterprise app',
      url: 'gratify'
    },
    {
      id: 3,
      name: 'Karrierestart',
      image: 'karrierestart',
      date: '2011 - NOW',
      description: 'Jobs marketplace and online recruitment solution',
      url: 'karriere-start'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
  [ServiceUrls.WebDevelopment]: {
    projects: [{
      id: 0,
      name: 'Charlie!',
      image: 'charlie',
      date: '2016 - 2017',
      description: 'Multibrand curated outfit marketplace',
      url: 'charlie'
    },
    {
      id: 1,
      name: 'ParrotPlay',
      image: 'parrotplay',
      date: '2015 - NOW',
      description: 'One online-TV for all streaming services',
      url: 'parrotplay'
    },
    {
      id: 2,
      name: 'Seequre',
      image: 'seequre',
      date: '2016 - NOW',
      description: 'Secure corporate messenger for MDaemon',
      url: 'seequre'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
  [ServiceUrls.EnterpriseAppsDevelopment]: {
    projects: [{
      id: 0,
      name: 'KAM',
      image: 'kam',
      date: '2017 - NOW',
      description: 'Campaign promotion enterprise app',
      url: 'kam'
    },
    {
      id: 1,
      name: 'Getwell',
      image: 'getwell',
      date: '2015 - 2016',
      description: 'SaaS EHR for ophtalmology practices',
      url: 'getwell'
    },
    {
      id: 2,
      name: 'Meld Bildskade',
      image: 'meldBildskade',
      date: '2016 - 2017',
      description: 'Car fleet control and Incident management app',
      url: 'meld-bildskade'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
  [ServiceUrls.IoTAppsDevelopment]: {
    projects: [{
      id: 0,
      name: 'Sensee',
      image: 'sensee',
      date: '2018 - NOW',
      description: 'Wireless Temperature Sensors for Food Safety',
      url: 'sensee'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
  [ServiceUrls.ChatbotDevelopment]: {
    projects: [
      {
        id: 0,
        name: 'Gratify',
        image: 'gratify',
        date: '2016 - NOW',
        description: 'Campaign promotion enterprise app',
        url: 'gratify'
      }],
      comments: [{
        id: 0,
        image: 'gratify',
        title: '“We wouldn\'t be able to build the product without them.”',
        description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
        name: 'James',
        position: '«Gratify» Founder',
        city: 'USA',
        date: '14/06/2019'
      },
      {
        id: 1,
        image: 'kam',
        title: '"I would highly recommend any similar organization to partner with them."',
        description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
        name: 'Pascal',
        position: '«KAM» Product Owner',
        city: 'Belgium',
        date: '20/12/2017'
      },
      {
        id: 2,
        image: 'seequre',
        title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
        description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
        name: 'Tomas',
        position: '"Seequre" CTO',
        city: 'Norway',
        date: '10/10/2019'
      }]
  },
  [ServiceUrls.Consulting]: {
    projects: [{
      id: 0,
      name: 'Foodlist',
      image: 'foodlist',
      date: '2016 - NOW',
      description: 'Near-expired food marketplace and giveaway',
      url: 'foodlist'
    },
    {
      id: 1,
      name: 'Seequre',
      image: 'seequre',
      date: '2016 - NOW',
      description: 'Secure corporate messenger for MDaemon',
      url: 'seequre'
    },
    {
      id: 2,
      name: 'VanGo',
      image: 'vango',
      date: '2018 - NOW',
      description: 'Rides for your teens with vetted, local moms',
      url: 'van-go'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
  [ServiceUrls.MobileAppsDevelopment]: {
    projects: [{
      id: 0,
      name: 'IKidDo',
      image: 'IKIDDO',
      date: '2018 - 2019',
      description: 'Chore app for kids and parents',
      url: 'ikiddo'
    },
    {
      id: 1,
      name: 'Eduspike',
      image: 'EDUSPIKE',
      date: '2016 - 2018',
      description: 'Gamified lectures chat',
      url: 'eduspike'
    },
    {
      id: 2,
      name: 'Blir Fint',
      image: 'BLIRFINT',
      date: '2016 - NOW',
      description: 'Craftsmen marketplace mobile app',
      url: 'blir-fint'
    }],
    comments: [{
      id: 0,
      image: 'gratify',
      title: '“We wouldn\'t be able to build the product without them.”',
      description: 'Unicreo produced high-quality code, enabling the in-house team to deliver an innovative platform on time. They went above and beyond to meet all needs, while their transparency, quick turnaround times, and personable approach made them a true partner. ',
      name: 'James',
      position: '«Gratify» Founder',
      city: 'USA',
      date: '14/06/2019'
    },
    {
      id: 1,
      image: 'kam',
      title: '"I would highly recommend any similar organization to partner with them."',
      description: 'No specific metrics are used to track progress, but Unicreo consistently meets expectations.While long-term planning could be improved, a clear workflow and transparent communication make them a trustworthy and reliable partner.',
      name: 'Pascal',
      position: '«KAM» Product Owner',
      city: 'Belgium',
      date: '20/12/2017'
    },
    {
      id: 2,
      image: 'seequre',
      title: '"They\'re a dedicated team that doesn’t punch out after four o\'clock, which I find very practical."',
      description: 'Their team has delivered nearly all milestones to perfection. They have project managers that are fluent in English and can capture project needs easily. Unicreo gets genuinely involved in projects and is available even during after-hours.',
      name: 'Tomas',
      position: '"Seequre" CTO',
      city: 'Norway',
      date: '10/10/2019'
    }]
  },
};
