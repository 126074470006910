/* eslint-disable */
import React, {Component} from 'react';
import './style.scss';
import Header from '../../images/landings/sensee-header.jpg';
import Logo from '../../images/landings/sensee-logo-1.svg';
import Rectangle from '../../images/landings/path-17.svg';
import Sensor from '../../images/landings/sensee-sensor.svg';
import Plan from '../../images/landings/plan-img.jpg';
import Illustration from '../../images/landings/illustration-6-2.png';
import Img from '../../images/landings/illustation-3@2x-2.png';
import Team from '../../images/landings/sensee_team.png';
import IconWeb from '../../images/landings/web_icon.svg';

class Sensee extends Component {
  render() {
    return (
      <div className="sensee">
        <div className="landing-header sensee__header">
          <img className="sensee__header-bg" src={Header} alt="Sensee"/>
          <img className="sensee-logo" src={Logo} alt="Sensee"/>
          <div className="sensee__header-block">
            <h1>Wireless Temperature Sensors
              for Food Safety</h1>
            <p>Smart sensors integrated with software, reporting and
              analytics installed in minutes at a fraction of the cost
              of traditional solutions.</p>
          </div>
        </div>
        <div className="sensee__main">
          <img className="sensee__main-top-bg" src={Rectangle} alt=""/>
          <div className="sensee__main-block">
            <div className="main-block-img">
              <img src={Sensor} alt="Sensee"/>
            </div>
            <div className="main-block-text">
              <h3 className="margin-top-0">Sensors</h3>
              <p>
                Senomate’s Most Valuable Player – our proprietary sensor design.
              </p>
              <p>
                Rugged, tough, wireless and battery powered.
                Scan them into your Senomate account via
                their unique QR code, give them a name,
                put them where you need them and forget
                about it for the next 7 years.
              </p>
              <p>
                Our market leading battery life and radio
                range is made possible by our proprietary
                hardware design. We’ve also built in
                laboratory-grade precision, external
                certifications and the ability to recalibrate
                as needed by your organization’s operational routines.
              </p>
              <p className="sensee__blockquote">
                Want a sensor package that you can sterilize?
                Want to monitor wet or damp areas?
                Our sensors are designed to work
                sealed inside of a disposable bag (kitchen bag or vacuum pack).
              </p>
              <div>
                <h3>Specs:</h3>
                <ul>
                  <li>Dimensions 8.89 cm x 4.45 cm x 2.54 cm</li>
                  <li>Sensor radio: proprietary Dash 7, ISM 433 Mhz</li>
                  <li>+/- 0.5c</li>
                  <li>-30c to +70c safe operating range</li>
                  <li>7 year battery life; Li-SOCl2</li>
                  <li>Online / mobile app calibration</li>
                </ul>
              </div>
            </div>
            <img className="plan" src={Plan} alt="Sensee"/>
          </div>
        </div>
        <div className="sensee__grey-block">
          <div>
            <h2>Protect inventory & customers</h2>
            <p>Automatic alerts notify users to take corrective
              action against equipment failures and human error
              before goods are spoiled – or worse, your customers
              are exposed to contaminated foods.</p>
            <img src={Img} alt=""/>
          </div>
          <img src={Illustration} className="img" alt=""/>
        </div>    
          <div className="landing-team">
            <h3 className="sidelines">
              <span>TEAM</span>
              <span className="sidelines-border">TECH STACK</span>
            </h3>
            <img src={Team} alt="Team"/>
          </div>
          <div className="sensee__technologies-wrapper">
            <div className="sensee__technologies">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies">
                <span className="technology">С#</span>
                <span className="technology">ASP.NET Core</span>
                <span className="technology">CosmosDb</span>
                <span className="technology">MongoDB</span>
                <span className="technology">Azure Service Bus</span>
                <span className="technology">Azure Iot Hub</span>
                <span className="technology">Azure Event Hubs</span>
                <span className="technology">Docker</span>
                <span className="technology">Kubernetes</span>
                <span className="technology">Azure DevOps</span>
                <span className="technology">Ocelot</span>
                <span className="technology">Serilog</span>
                <span className="technology">Azure</span>
                <span className="technology">.NET Core</span>
                <span className="technology">Azure Blob Storage</span>
                <span className="technology">Redis</span>
              </div>
            </div>
          </div>
        <div className="landing-info isMediumGrey">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Kristian</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2019 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="https://www.sensee.io" target="_blank">sensee.io</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Sensee;
