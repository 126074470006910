/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import { Link } from "react-router-dom";

export default class Service extends Component {
  render() {
    const {
      icon, firstLine, secondLine, thirdLine, url
    } = this.props;
    return (
      <Link className="service-container" to={`/services/${url}`}>
        <div className="icon-container">
          <img src={icon} className="service-icon" alt="Service Icon" />
        </div>
        <div className="row-lines">
          <span className="service-text" style={{ opacity: firstLine ? 1 : 0 }}>{firstLine || 'Hide'}</span>
          <span className="service-text" style={{ opacity: secondLine ? 1 : 0 }}>{secondLine || 'Hide'}</span>
          <span className="service-text" style={{ opacity: thirdLine ? 1 : 0 }}>{thirdLine || 'Hide'}</span>
        </div>
      </Link>
    );
  }
}
