/* eslint-disable */
import React, { Component } from 'react';
import enterprise from '../../../images/enterprise.svg';
import bg from '../../../images/rectangle-bg.jpg';
import lines from '../../../images/lines.svg';
import './style.scss';
import {ContactUsContext} from "../../../App";

export default class EnterpriseDevelopment extends Component {
  render() {
    return (
      <div className="service-item">
        <div className="rectangle-bg isEnterprise-bg">
          <img src={bg} alt="rectangle-bg"/>
        </div>
        <div className="container-top isEnterprise">
          <img className="container-top__lines" src={lines} alt=""/>
          <h2 className="container-top__title">
            <img src={enterprise} alt="Icon Enterprise"/>
            ENTERPRISE APPS DEVELOPMENT
          </h2>
          <p>
            Digitization in the business world is the
            fastest growing competitive advantage,
            while products that prompt business
            and process automation is the heart of every organization.
          </p>
          <p>
            At Unicreo, we're focused on developing highly-secure,
            fast, robust, and user-focused enterprise applications.
            With over ten years of experience in building products
            for supporting business automation, we offer multiple
            industries-specific products tailored towards maximizing
            performance within an organization.
          </p>
          <p>
            We produce enterprise solutions through a bunch
            of deliverables that you can use across the
            entire business. Depending on the business needs,
            your customized solution may include web, desktop,
            and mobile applications, and even a private cloud setup.
          </p>
          <p>
            Besides of application development,
            our range of services includes integration
            with current enterprise infrastructure and
            legacy project upgrade. We integrate multiple
            IT systems into your existing infrastructure,
            to strengthen it and facilitate operational
            aspects such as data collection, storage,
            processing, and more. In case when the
            enterprise system is not viable for
            brand-new digital operations, we upgrade
            outdated systems to make sure your enterprise
            is agile and relevant. Before upgrading your
            legacy software system, we're determining the
            features and applications your enterprise will
            need for smooth functioning. Once we complete
            our work, you get not only a modern solution
            but a profit tool and competitive advantage.
          </p>
          <ContactUsContext.Consumer>
            {({ handleContactUsModalOpen }) => (
              <a onClick={handleContactUsModalOpen} className="link-contact-us">
                Are you looking for experts in enterprise app development? Unicreo is your choice!</a>
            )}
          </ContactUsContext.Consumer>
        </div>
      </div>
    );
  }
}
