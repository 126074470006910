/* eslint-disable */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import ModalForm from '../ModalForm/ModalForm';
import { ContactUsContext } from '../../App';


class TestModal extends Component {
  render() {
    const modalCalsses = {
      modal: 'contact-us-modal',
      closeButton: 'modal-close-btn',
      overlay: 'modal-overlay',
    };
    return (
      <ContactUsContext.Consumer>
        {({ handleContactUsCloseModal, state }) => (
          <Modal open={state.showContactUsModal} onClose={handleContactUsCloseModal} center classNames={modalCalsses} blockScroll={false}>
            <ModalForm onCloseModal={handleContactUsCloseModal} />
          </Modal>
        )}
      </ContactUsContext.Consumer>
    );
  }
}

export default TestModal;
