import React, { Component } from 'react';
// import mobile from '../../../images/mobile.svg';
// import lines from '../../../images/lines-2.svg';
import './style.scss';
import ServicesRowItem from './ServicesBlocks/ServicesRowItem';
import { ServiceDetailsData } from '../../Mock/serviceDetails';
import { ServiceUrls } from '../../Mock';
import ReadMoreBlock from './ServicesBlocks/ReactMoreBlock';

export default class ServiceDetails extends Component {
  state = {
    isShown: false,
  }

  componentWillReceiveProps(nextProps) {
    const { pageID } = this.props;
    if (pageID !== nextProps.pageID) this.setState({ isShown: false });
  }

  onReadMore = () => {
    this.setState({ isShown: true });
  }

  render() {
    const { isShown } = this.state;
    const { pageID } = this.props;
    const page = ServiceDetailsData[pageID];
    console.log('PAGE =>', page);
    return (
      <div className="service-item isWeb">
        <h2 className="container-top__title">
          <img src={page.pageIcon} alt="" />
          {page.pageName}
        </h2>
        <div className="service-item__info">

          <div className="service-item__description">
            <p>{page.description}</p>
          </div>
          <div className="read-more-mobile">
            {page.readMore.firstColumn && <ReadMoreBlock readMore={page.readMore} isShown={isShown} onReadMorelick={this.onReadMore} /> }
          </div>
          <div className="service-item__options">
            <ServicesRowItem items={page.options} rowNum={1} />
            <ServicesRowItem items={page.options} rowNum={2} />
            {page.options.length === 6 && <ServicesRowItem items={page.options} rowNum={3} /> }
          </div>

        </div>
        <div className="read-more-web">
          {page.readMore.firstColumn && <ReadMoreBlock readMore={page.readMore} isShown={isShown} onReadMorelick={this.onReadMore} /> }
        </div>
      </div>
    );
  }
}
