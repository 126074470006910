/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import { PointerDirection } from '../../Containers/Team';
import Bot from '../../images/bot.svg';
var classNames = require('classnames');

export default class Item extends Component {
  onPersonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.onPersonClick(this.props.person, event);
  }
  render() {
    const { person, infoPointerDirection } = this.props;
    const photo = person.photo && require(`../../teamPhotos/${person.photo}.png`);
    const icon = person.icon && require(`../../images/teamIcons/${person.icon}.png`)
    const personalInfoStyles = classNames({
      'item__person-info-ishidden': !person.isSelected,
      'item__person-info': person.isSelected,
      'item__person-info-right': person.isSelected && infoPointerDirection === PointerDirection.Right,
      'item__person-info-left': person.isSelected && infoPointerDirection === PointerDirection.Left,
    })
    const personInformationsStyles = classNames({
      'item__person-informations': true,
      'item__person-informations-rotate': infoPointerDirection === PointerDirection.Left
    })
    return (
      <div id={`person-${person.id}`} className='item' style={{ zIndex: person.isSelected ? 700 : 100 }}>
        <div className="hexagon hexagon1" onClick={this.onPersonClick}>
          <div className="hexagon-in1">
            {photo ?
              <div className="hexagon-in2" style={{ backgroundImage: `url(${photo})` }}></div>
              :
              <div className="hexagon-in2" ></div>
            }
          </div>
        </div>
        <div className={personalInfoStyles}>
          <div className={personInformationsStyles}>
            <span className='person-name'>{(person.name || '').toUpperCase()}</span>
            <span className='person-position'>{person.position || ''}</span>
            <div className='person-nickname'>
              <img src={icon || Bot} alt="" className='person-nickname-icon' />
              <span className='person-nickname-text'>{person.nickname}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
