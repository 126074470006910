/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import HeaderBg from '../../images/landings/gratify-header.jpg';
import Logo from '../../images/landings/gratify-logo.png';
import Btn from '../../images/landings/gratify-btn-slack.png';
import Screen from '../../images/landings/gratify-screen.png';
import MainImg from '../../images/landings/gratify-main-img.jpg';
import Team from '../../images/landings/gratify-team.svg';

class Gratify extends Component {
  render() {
    return (
      <div className="gratify">
        <div className="landing-header gratify__header">
          <img className="gratify__header-bg" src={HeaderBg} alt="Gratify"/>
          <img className="gratify__logo" src={Logo} alt="Gratify Logo"/>
          <div className="gratify__header-description">
            <p>Hire <span>support</span> experts on-demand via Slack</p>
            <div className="gratify__header-text">
              <span>design</span>
              <span>dev</span>
              <span>media</span>
              <span>writing</span>
              <span>shopify</span>
              <span>marketing</span>
            </div>
          </div>
        </div>
        <div className="gratify__main">
          <div className="gratify__main-block">
            <p>Gratify is Slack add-on that puts
              you in touch with technical experts 24/7.</p>
            <p>Meet, hire and pay expert
              freelancers without leaving Slack</p>
            <img src={Btn} alt="Add to Slack"/>
          </div>
          <img className="gratify__screen" src={Screen} alt="Gratify Screen"/>
        </div>
        <div className="gratify__section">
          <img src={MainImg} alt="Gratify"/>
          <div className="gratify__main-text">
            <h4>Vetted talent</h4>
            <p>Our team regularly interviews new freelancers,
              checking previous work experience and Slack proficiency,
              to ensure real experts are on-hand when you post a task.</p>
            <h4>Stay in your workspace</h4>
            <p>Gratify works from within your Slack workplace
              and brings the experts to you. Once a bid has
              been accepted, your expert will be available
              in a private channel.</p>
            <h4>Secure payments via</h4>
            <p>Upon completion, you can pay quickly and
              securely with Gratify (via Stripe) without
              leaving your Slack workspace.</p>
            <h4>Future-focused</h4>
            <p>Gratify utilizes natural language processing
              combined with real human operators to drive
              a seamless, contextually-aware conversational
              UI experience.</p>
          </div>
        </div>
        <div className="landing-team">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span></h3>
          <img src={Team} alt="Team" />
        </div>
        <div className="gratify__technologies-wrapper">
          <div className="technologies">
            <span className="technology">Node.JS</span>
            <span className="technology">Git</span>
            <span className="technology">HTML5</span>
            <span className="technology">CSS</span>
            <span className="technology">Redis</span>
            <span className="technology">AWS</span>
            <span className="technology">PostgreSQL</span>
            <span className="technology">Heroku</span>
            <span className="technology">SlackAPI</span>
            <span className="technology">Bootkit</span>
            <span className="technology">Stripe</span>
          </div>
        </div>
        <div className="landing-info isBlack">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>James</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2016 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="https://www.gratify.ai" target="_blank">gratify.ai</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Gratify;
