/* eslint-disable */
import React, { Component } from 'react';
import DropDownItem from './DropDownItem';
import './style.scss';

export default class DropDown extends Component {

  render() {
    const { top, left, refDrop, items, onItemClick } = this.props;
    const headerHeight = 39;

    return (
      // TODO
      <div className="dropdown-container" style={{ top: `${top + headerHeight}px` || 0, left: `${left}px` || 0 }} ref={(ref) => refDrop && refDrop(ref)}>
        <div className="dropdown-list">
          {items && items.length !==0 && items.map((item, index) => (
            <DropDownItem icon={item.icon} description={item.description || ''} key={item.id} onItemClick={() => onItemClick(item)}/>
          ))}
        </div>
      </div>
    );
  }
}
