/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import './style.scss';
import { withRouter, Link } from 'react-router-dom';
import IconFacebook from '../../images/icon-facebook.svg';
import behance from '../../images/behance.svg';
import IconInsta from '../../images/icon-instagram.svg';
import IconLinkedin from '../../images/icon-linkedin.svg';
import navArrow from '../../images/navArrow.svg';
import { ContactUsContext } from '../../App';
import { ServiceUrls } from '../../Mock';


const SocialLincksBlock = () => (
  <div className="social-links">
    <a href="https://www.behance.net/eyesissoul/" target="_blank" rel="noopener noreferrer">
      <img src={behance} className="social-links__icon" alt="Behance" />
    </a>
    <a href="https://facebook.com/Unicreo" target="_blank" rel="noopener noreferrer">
      <img src={IconFacebook} className="social-links__icon" alt="Icon Facebook" />
    </a>
    <a href="https://instagram.com/unicreo" target="_blank" rel="noopener noreferrer">
      <img src={IconInsta} className="social-links__icon" alt="Icon Instagram" />
    </a>
    <a href="https://www.linkedin.com/company/unicreo" target="_blank" rel="noopener noreferrer">
      <img src={IconLinkedin} className="social-links__icon" alt="Icon Linkedin" />
    </a>
  </div>
);
const NavItemWithArrow = ({
  name, withLargeFont, withoutArrow, customStyle, largeBottomMargin, url,
}) => (
  <Link to={`${url}`} onClick={e => !url && e.preventDefault()}>
    <div className="nav-item-container" style={{ ...customStyle, cursor: withoutArrow ? 'default' : 'pointer' }}>
      <span className="nav-item-name" style={withLargeFont && { fontSize: '20px', lineHeight: '24px' }}>{name}</span>
      {!withoutArrow && <img src={navArrow} className="nav-item-arrow" alt="" />}
    </div>
  </Link>
);

const BreadCrumps = ({ breadCrumps }) => {
  console.log('breadCrumpsbreadCrumps =>', breadCrumps);
  return (
    <div className="bread-crumps-container">
      {breadCrumps.map((item, index) => {
        const lastItem = index === breadCrumps.length - 1;
        return (
          <Link to={`${item.url}`} onClick={e => lastItem && e.preventDefault()}>
            <div className="bread-crump">
              <span className="bread-crumps-name" style={{ color: lastItem ? 'rgba(255, 255, 255, 0.5)' : 'white' }}>{item.name}</span>
              {!lastItem && <img src={navArrow} alt="" className="bread-crumps-arrow" /> }
            </div>
          </Link>
        );
      })}
    </div>
  );
};

class Footer extends Component {
  state = {
    breadCrumps: [{
      url: '/',
      name: 'Home',
    }],
  }

  componentDidMount() {
    const { location } = this.props;
    this.getBreadCrumps(location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (location.pathname !== nextProps.location.pathname) {
      this.getBreadCrumps(nextProps.location.pathname);
    }
  }

  getCorrectName = (pathes) => {
    let correctName = null;
    if (pathes[0] === 'services') correctName = 'Services';
    if (pathes[0] === 'approach') correctName = 'Approach';

    return correctName;
  }

  getUpr = word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`

  getBreadCrumps = (pathname) => {
    const breadCrumps = [{
      url: '',
      name: 'Home',
    }];
    let pathes = pathname.split('/').slice(1);
    const correctName = this.getCorrectName(pathes);
    if (correctName) pathes = pathes.slice(1);

    pathes.forEach((item, index) => {
      const isLast = index === pathes.length - 1;
      if (item) {
        const crump = { url: '', name: '' };
        if (item.includes('-')) {
          let str = '';
          item.split('-').forEach((i) => {
            str = `${str} ${this.getUpr(i)}`;
          });

          crump.url = `${isLast ? pathname : `/${item}`}`;
          crump.name = correctName ? `${correctName} - ${str}`.trim() : str.trim();
          breadCrumps.push(crump);
        } else {
          crump.url = `${isLast ? pathname : `/${item}`}`;
          crump.name = correctName ? `${correctName} - ${this.getUpr(item)}`.trim() : `${this.getUpr(item)}`.trim();
          breadCrumps.push(crump);
        }
      }
    });
    console.log('breadCrumps =>', breadCrumps);
    this.setState({ breadCrumps });
  }


  render() {
    const { match, location, history } = this.props;
    const { breadCrumps } = this.state;
    console.log('breadCrumps =>', breadCrumps);
    // console.log('match => ', match);
    // console.log('location => ', location);
    // console.log('history => ', history);
    return (
      <ContactUsContext.Consumer>
        {({ handleContactUsModalOpen, state, handleMobileMenuSwitch }) => (

          <footer className="footer">
            <BreadCrumps breadCrumps={breadCrumps} />
            <div className="nav-conatiner">
              <div className="nav-left-conatiner">
                <div className="left-column">
                  <NavItemWithArrow name="SERVICES" withLargeFont withoutArrow />
                  <NavItemWithArrow name="Team Augmentation" url={`/services/${ServiceUrls.TeamAugmentation}`} />
                  <NavItemWithArrow name="Web Development" url={`/services/${ServiceUrls.WebDevelopment}`} />
                  <NavItemWithArrow name="Enterprise Apps Development" url={`/services/${ServiceUrls.EnterpriseAppsDevelopment}`} />
                  <NavItemWithArrow name="Mobile Apps Development" url={`/services/${ServiceUrls.MobileAppsDevelopment}`} />
                  <NavItemWithArrow name="IoT Apps Development" url={`/services/${ServiceUrls.IoTAppsDevelopment}`} />
                  <NavItemWithArrow name="Chatbot Development" url={`/services/${ServiceUrls.ChatbotDevelopment}`} />
                  <NavItemWithArrow name="Consulting" url={`/services/${ServiceUrls.Consulting}`} />
                </div>
                <div className="right-column">
                  <NavItemWithArrow name="WORKS" withLargeFont url="/works" />
                  <NavItemWithArrow name="APPROACH" withLargeFont withoutArrow customStyle={{ marginTop: '22px' }} />
                  <NavItemWithArrow name="Process" url="/approach/process" />
                  <NavItemWithArrow name="ABOUT US" withLargeFont customStyle={{ marginTop: '22px' }} url="/about-us" />
                  <NavItemWithArrow name="HOME" withLargeFont customStyle={{ marginTop: '22px' }} url="/" />
                </div>
              </div>
              <div className="nav-right-conatiner">
                <div className="left-column">
                  <span className="nav-item-skill first-item-skill">ASP.NET MVC</span>
                  <span className="nav-item-skill">.NET Core</span>
                  <span className="nav-item-skill">С#</span>
                  <span className="nav-item-skill">Azure</span>
                  <span className="nav-item-skill">Node.js</span>
                  <span className="nav-item-skill">UI/UX</span>
                  <span className="nav-item-skill">Swift</span>
                  <span className="nav-item-skill">Objective C</span>
                </div>
                <div className="right-column">
                  <span className="nav-item-skill">Java</span>
                  <span className="nav-item-skill">Kotlin</span>
                  <span className="nav-item-skill">React Native</span>
                  <span className="nav-item-skill">Ionic</span>
                  <span className="nav-item-skill">Flutter</span>
                  <span className="nav-item-skill">React</span>
                  <span className="nav-item-skill">Angular</span>
                </div>

              </div>
            </div>
            <div className="contact-us-mobile">
              <div className="contact-us-btn" onClick={handleContactUsModalOpen}>
                <span className="contact-us-text">Contact Us</span>
              </div>
              <SocialLincksBlock />
            </div>
            <div className="contact-info">
              <div className="left-column">
                <div className="phone-mail-info">
                  <a href="/" className="contacts__site">www.unicreo.com</a>
                  <a href="mailto:info@unicreo.com" className="contacts__email">info@unicreo.com</a>
                  <p className="contacts__phones">
                    <a href="tel:+1(813)99008441">+1 (813) 99 00 844</a>
                    <a href="tel:+380(50)6090439">+380 (50) 609 04 39</a>
                  </p>
                </div>

                <p className="contacts__address">
                  <span>9d Sichovykh Strliltsiv str.,</span>
                  <span>Office 6,</span>
                  <span>49000 Dnipro, Ukraine</span>
                </p>
              </div>
            </div>
            <div className="contact-us-block">
              <div className="invise-block" />
              <div className="contact-us-btn" onClick={handleContactUsModalOpen}>
                <span className="contact-us-text">Contact Us</span>
              </div>
              <SocialLincksBlock />
            </div>

            {/* <div className="footer__left contacts">
          <a href="/" className="contacts__site">www.unicreo.com</a>
          <a href="mailto:info@unicreo.com" className="contacts__email">info@unicreo.com</a>
          <p className="contacts__phones">
            <a href="tel:+1(813)99008441">+1 (813) 99 00 844</a>
            <a href="tel:+380(50)6090439">+380 (50) 609 04 39</a>
          </p>
          <p className="contacts__address">
            <span>9d Sichovykh Strliltsiv str.,</span>
            <span>Office 6,</span>
            <span>49000 Dnipro, Ukraine</span>
          </p>
        </div>
        <div className="footer__right">
          <h4 className="footer__title">Contact</h4>
          <div className="social-links">
            <a href="https://facebook.com/Unicreo" target="_blank">
              <img src={IconFacebook} className="social-links__icon" alt="Icon Facebook" />
            </a>
            <a href="https://instagram.com/unicreo" target="_blank">
              <img src={IconInsta} className="social-links__icon" alt="Icon Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/unicreo" target="_blank">
              <img src={IconLinkedin} className="social-links__icon" alt="Icon Linkedin" />
            </a>
          </div>
        </div> */}
          </footer>
        )}
      </ContactUsContext.Consumer>
    );
  }
}

export default withRouter(Footer);
