import React from 'react';
import ServiceDetailsItem from './ServicesItem';
import './style.scss';

const ServicesRowItem = ({ items, rowNum }) => {
  let firstEl = 0;
  if (rowNum === 2) firstEl = rowNum;
  if (rowNum === 3) firstEl = rowNum + 1;
  const firstElem = items[firstEl];
  const secondElem = items[firstEl + 1];
  return (
    <div className="service-options-row">
      {firstElem && <ServiceDetailsItem text={firstElem.text} title={firstElem.title} icon={firstElem.icon} />}
      {secondElem && <ServiceDetailsItem text={secondElem.text} title={secondElem.title} icon={secondElem.icon} />}
    </div>
  );
};

export default ServicesRowItem;
