/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import { Link } from "react-router-dom";

export default class CarouselItem extends Component {
  render() {
    const { description, image, url } = this.props;
    return (
      <div className="carousel-item">
        <div className="project-descritption">
          <span>
            {description}
          </span>
        </div>
        <div className="carousel-project-img">
          <Link to={`/works/${url}`}>
            <div className="more-btn">
              <span className="more-btn-text">MORE</span>
            </div>
          </Link>
          <img src={image} alt="" className="project-img" />
        </div>
      </div>
    );
  }
}
