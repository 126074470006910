/* eslint-disable */
import React, { Component } from 'react';
import Service from '../Components/Services/Service';
import './style.scss';
import Team from '../images/team.svg';
import Web from '../images/web.svg';
import Enterprise from '../images/enterprise.svg';
import Mobile from '../images/mobile.svg';
import Consalting from '../images/consulting.svg';
import Bot from '../images/bot.svg';
import Iot from '../images/iot.svg';
import {ServiceUrls} from "../Mock";

export default class Services extends Component {
  render() {

    return (
      <div className="services">
        <div className="services__top">
          <Service icon={Team} url={ServiceUrls.TeamAugmentation} secondLine="TEAM" thirdLine="AUGMENTATION" />
          <Service icon={Web} url={ServiceUrls.WebDevelopment} secondLine="WEB" thirdLine="DEVELOPMENT" />
          <Service icon={Enterprise} url={ServiceUrls.EnterpriseAppsDevelopment} firstLine="ENTERPRISE" secondLine="APPS" thirdLine="DEVELOPMENT" />
        </div>
        <div className="services__bottom">
          <Service icon={Mobile} url={ServiceUrls.MobileAppsDevelopment} firstLine="MOBILE" secondLine="APPS" thirdLine="DEVELOPMENT" />
          <Service icon={Iot} url={ServiceUrls.IoTAppsDevelopment} firstLine="IoT" secondLine="APPS" thirdLine="DEVELOPMENT" />
          <Service icon={Bot} url={ServiceUrls.ChatbotDevelopment} secondLine="CHATBOT" thirdLine="DEVELOPMENT" />
          <Service icon={Consalting} url={ServiceUrls.Consulting} secondLine="CONSULTING" />
        </div>
      </div>
    );
  }
}
