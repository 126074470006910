/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import { Link } from "react-router-dom";

export default class GalleryItem extends Component {
  render() {
    const {item} = this.props;
    let img = '';
    if (item.image) img = require(`../../images/projectSquare/${item.image}.png`);
    const href = `/works/${item.url}`;

    return (
      <Link to={href}>
        <div className='work-gallery-item' style={{opacity: img ? 1 : 0}}>
          <div className='item-content'>
            <div className='item-content-img-wrapper'>
              <img src={img} alt={item.name} className='item-content-img'/>
            </div>
            <div className='item-content-name'>
              <span>{item.name}</span>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
