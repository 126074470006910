/* eslint-disable */
import React, { Component, Fragment } from 'react';
import ProjectCarousel from "./ProjectCarousel";
import CommentsCarousel from "./CommentsCarousel";

export default class ServicesContent extends Component {
  state = {};

  render() {
    const { data }  = this.props;
    const projects = data.projects;
    const comments = data.comments;

    return (
      <Fragment>
        <ProjectCarousel listOfProjects={projects}/>
        <CommentsCarousel listOfComments={comments}/>
      </Fragment>
    );
  }
}
