/* eslint-disable */
import React, { Component } from 'react'
import { Link } from "react-router-dom";

export default class ProjectCarouselItem extends Component {

  render() {

    // const {
    //   image,
    //   name,
    //   date,
    //   description
    // } = this.props;

    const{ data } = this.props;
    const image = data.image && require(`../../images/projectSquare/${data.image}.png`);

    return (
      <Link className='project-carousel-item' to={`/works/${data.url}`}>
        <div className='item-project-image'>
          <img src={image} alt="" className='item-img'/>
        </div>
        <div className='item-project-info-container'>
          <div className='item-project-info'>
            <span>{data.name}</span>
            <span className="item-date">{data.date}</span>
          </div>
          <div className='item-project-description'>
            <span>{data.description}</span>
          </div>
        </div>
      </Link>
    )
  }
}
