/* eslint-disable */
import React, { Component } from 'react';
import lines from '../../../images/lines-with-circle.svg';
import bot from '../../../images/bot.svg';
import './style.scss';
import {ContactUsContext} from "../../../App";

export default class ChatbotDevelopment extends Component {
  render() {
    return (
      <div className="service-item isChatbot">
        <div className="container-top">
          <img className="container-top__lines" src={lines} alt=""/>
          <h2 className="container-top__title">
            <img src={bot} alt="Icon Chatbot"/>
            CHATBOT DEVELOPMENT
          </h2>
          <p>
            Are you having tough times reaching and engaging your clients?
            Do you want to simplify & automate the customer experience?
            Reach your current and potential customers in their
            favourite instant messenger via chatbot! We’re here
            to help you with robust and smart conversational applications
            based on your business needs.
          </p>
          <p>
            At Unicreo, we develop chatbots with effective conversational
            UX strategy that promotes user engagement and makes each
            person feel unique, as the conversation is tailored to
            customer’s needs. Chatbots can be implemented into different
            business fields from e-commerce to corporate information exchange.
          </p>
          <p>
            Besides customer outreach, a chatbot is the most efficient low-cost business
            model validation method. Collecting feedback from a large number of people
            without asking them to download additional application has never been that
            easy! With a chatbot, you can gather feedback on your business idea and
            instantly accept pre-orders or payments for your product or service
            through multiple applications your potential customers are using daily.
          </p>
          <p>
            Our team of developers, data scientists, and business analysts
            provides full service of chatbot development from idea to
            publishing and support. Start building a digital bridge
            between your business and your customers
            <ContactUsContext.Consumer>
              {({ handleContactUsModalOpen }) => (
                <a onClick={handleContactUsModalOpen} className="link-contact-us">now!</a>
              )}
            </ContactUsContext.Consumer>
          </p>
        </div>
      </div>
    );
  }
}
