/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import dropArrow from '../../images/arrow-dropdown.svg';
import DropDown from '../DropDown/DropDown';
import { ServicesItems, ApproachItems, ListType } from '../../Mock';
import { ContactUsContext } from '../../App';
import { withRouter } from 'react-router-dom';

const classNames = require('classnames');

class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowDropDown: false,
      top: 0,
      left: 0,
      dropDownItems: []
    }
  }

  onTabMouseLeave = () => {
    if (document.documentElement.clientWidth > 768) {
      this.setState({ isShowDropDown: false, top: 0, left: 0 })
    }
  };
  closeDropDown = () => {
    this.setState({ isShowDropDown: false, top: 0, left: 0})
  };

  onTabMouseEnter = () => {
    if (document.documentElement.clientWidth > 768) {
      const { tab } = this.props;
      let elem = document.getElementById(tab.name);
      let coords = elem.getBoundingClientRect();
      let dropDownItems = tab.id === 0 ? ServicesItems : ApproachItems;
      if (tab.isDropDown)
        this.setState({ isShowDropDown: true, top: coords.top, left: coords.left, dropDownItems })

    }
  };

  onItemClick = (handleMobileMenuSwitch) => (item) => {
    let { history, onTabClick, tab } = this.props;


    if(item.type === ListType.Services) {
      history.push(`/services/${item.url}`);
      this.closeDropDown();
      handleMobileMenuSwitch(false)()
      return onTabClick(tab, true)
    }

    if(item.type === ListType.Approach) {
      history.push(`/approach/${item.url}`);
      this.closeDropDown();
      handleMobileMenuSwitch(false)()
      return onTabClick(tab, true);
    }
    handleMobileMenuSwitch(false)()


  };

  handleDropDown = (tab) => {
    console.log('handleDropDown')
    let elem = document.getElementById(tab.name);
    let coords = elem.getBoundingClientRect();
    let dropDownItems = tab.id === 0 ? ServicesItems : ApproachItems;

    if (tab.isDropDown && !this.state.isShowDropDown) {
      this.setState({ isShowDropDown: true, top: coords.top, left: coords.left, dropDownItems})
    } else {
      this.closeDropDown()
    }
    this.props.onTabClick(tab)
  };

  render() {
    const { tab, onTabClick } = this.props;
    const { isShowDropDown, top, left, dropDownItems } = this.state;
    const tabClassNames = classNames({
      tab: true,
      'tab-active': tab.active,
    });
    return (
      <ContactUsContext.Consumer>
        {({ handleMobileMenuSwitch }) => (
          <div
            id={tab.name}
            className={tabClassNames}
            onClick={() => this.handleDropDown(tab)}
            onMouseEnter={this.onTabMouseEnter}
            onMouseLeave={this.onTabMouseLeave}

          >
            <span className='tab-item'>
              <span className='tab-name'>{(tab.name).trim()}</span>
              {tab.isDropDown && <img src={dropArrow} alt="" className='tab-drop-arrow'/>}
            </span>  
            {isShowDropDown && <DropDown top={top} left={left} items={dropDownItems} onItemClick={this.onItemClick(handleMobileMenuSwitch)}/>}
          </div>
        )}
      </ContactUsContext.Consumer>
    );
  }
}
export default withRouter(Tab)
