/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/kam_logo.png';
import Screen from '../../images/landings/kam-header-screen@2x.png';
import HeaderBg from '../../images/landings/kam_back_long.jpg';
import MainBg from '../../images/landings/kam_back.png';
import Team from '../../images/landings/kam_team.png';
import Triangle from '../../images/landings/kam-team-bg.png';

class Kam extends Component {
  render() {
    return (
      <div className="kam">
        <div className="landing-header kam__header">
          <img className="kam__bg" src={HeaderBg} alt=""/>
          <div className="kam__header-block">
            <img className="kam__logo" src={Logo} alt="Kam Logo" />
            <img className="kam__screen" src={Screen} alt="Screen" />
          </div>
        </div>
        <div className="kam__text">
          <p>
            KAM is an integrated solution supporting the
            entire process of promotional planning, control and evaluation.
          </p>
          <p>
            The aim of software is to help sales teams
            to set up trade promotion programs more efficiently
            and easier. Software has two modules: analysis
            and reporting. Reporting module allows printing
            and exporting promotional programs and technical
            documents in a variety of formats for internal use,
            for use by sales reps, for the retailer. Several
            layouts can be defined based on the retailer's
            or your internal requirements and any trade
            promotion program can be printed or exported
            into any desired format. Analysis module allows
            building handy lists and pivoting report tables
            showing aggregated sales and promotion information,
            selected using user-defined criteria and table layouts.
          </p>
        </div>
        <div className="kam__main">
          <img className="kam__main-screen" src={MainBg} alt="" />
        </div>
        <div className="landing-team">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span>
          </h3>
          <img src={Team} alt="Team"/>
        </div>
        <div className="bg">
          <img className="triangle-bg" src={Triangle} alt=""/>
        </div>
        <div className="kam__technologies-wrapper">
          <div className="technologies">
            <span className="technology">TypeScript</span>
            <span className="technology">MS SQL SERVER</span>            
            <span className="technology">ASP.NET MVC</span>            
            <span className="technology">Entity Framework</span>
            <span className="technology">C#</span>
            <span className="technology">.NET</span>
            <span className="technology">T-SQL</span>
            <span className="technology">WEB API</span>
            <span className="technology">LINQ</span>
            <span className="technology">XAML</span>
            <span className="technology">Angular 2+</span>
            <span className="technology">Autofac</span>
            <span className="technology">jQuery</span>
            <span className="technology">HTML5</span>
            <span className="technology">CSS3</span>
          </div>
        </div>
        <div className="landing-info isDarkGrey">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Pascal</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2017 to now</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Kam;
