/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import HeaderBg from '../../images/landings/girls_charlie.jpg';
import HeaderBgBottom from '../../images/landings/charlie-header-bg-bottom.svg';
import Screen from '../../images/landings/charlie_two_screens.png';
import Logo from '../../images/landings/charlie-logo.svg';
import Img from '../../images/landings/charlie-img.png';
import Team from "../../images/landings/charlie_team.png";
import IconWeb from '../../images/landings/charlie-icon-web.svg';

class Charlie extends Component {
  render() {
    return (
      <div className="charlie">
        <div className="landing-header">
          <img className="charlie__header-bg" src={HeaderBg} alt="Charlie"/>
          <img className="charlie__header-bg" src={HeaderBgBottom} alt="Charlie"/>
          <div className="charlie__header-screen">
            <img src={Screen} alt="Charlie"/>
          </div>
        </div>
        <div className="charlie__main">
          <div className="charlie__main-block">
            <img src={Logo} alt="Charlie"/>
            <p>
              Marketplace supporting
              and promoting highly curated emerging
              & established brands that have high
              quality of products backed by incredibly
              talented designers!
            </p>
          </div>
        </div>
        <div className="charlie__bg-wrapper">
          <img className="charlie__img" src={Img} alt=""/>
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span></h3>
          <div className="landing-team">
            <img src={Team} alt="Team" />
          </div>
          <div className="charlie__technologies-wrapper">
            <img src={IconWeb} alt="WEB"/>
            <div className="technologies">
              <span className="technology">MS SQL SERVER </span>
              <span className="technology">.NET</span>
              <span className="technology">ASP.NET MVC</span>
              <span className="technology">Swift</span>
              <span className="technology">IOS</span>
              <span className="technology">C#</span>
              <span className="technology">Entity Framework</span>
              <span className="technology">SQL Server</span>
              <span className="technology">T-SQL</span>
              <span className="technology">HTML</span>
              <span className="technology">JavaScript</span>
              <span className="technology">CSS</span>
              <span className="technology">Angular JS</span>
              <span className="technology">Azure Web Services</span>
              <span className="technology">Azure Insights</span>
              <span className="technology">Azure DB</span>
              <span className="technology">Google Insights</span>
              <span className="technology">Facebook API</span>
              <span className="technology">Google API</span>
              <span className="technology">Twitter API</span>
              <span className="technology">PayPal</span>
              <span className="technology">Braintree</span>
              <span className="technology">Hangfire</span>
              <span className="technology">Autofac</span>
              <span className="technology">Owin</span>
            </div>
          </div>
        </div>
        <div className="landing-info isDarkRed">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Sumeet</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>2016-2017</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Charlie;
