/* eslint-disable */
import React, { Component } from 'react';
import UnicreoLogo from '../../images/uncr-logo.png';
import Phone from '../../images/phone-call.png';
import './style.scss';
import U from '../../images/logo/u.svg';
import N from '../../images/logo/n.svg';
import I from '../../images/logo/i.svg';
import C from '../../images/logo/c.svg';
import R from '../../images/logo/r.svg';
import E from '../../images/logo/e.svg';
import O from '../../images/logo/o.svg';
import { ContactUsContext } from '../../App';

var classNames = require('classnames');

export default class Hero extends Component {

  constructor(props) {
    super(props);
    this.state= {
      isShown: false
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ isShown: true})
    }, 2000)
  }
  render() {
    let { isShown } = this.state;
    const hiddenLetters = classNames({
      'hidden-letter': true,
      'letter-is-shown': isShown
    })
    const horLine = classNames({
      'hor-line': isShown,
      'hor-line-hidden': true
    })
    return (
      <section id="0">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__logo">
              <img src={U} alt="Unicreo" className='logo-letter'/>
              <img src={N} alt="Unicreo" className='logo-letter'/>
              <img src={I} alt="Unicreo" className={hiddenLetters}/>
              <img src={C} alt="Unicreo" className='logo-letter'/>
              <img src={R} alt="Unicreo" className='logo-letter'/>
              <img src={E} alt="Unicreo" className={hiddenLetters} />
              <img src={O} alt="Unicreo" className={hiddenLetters} />
            </div>
          </div>
          <div className="hero__call-block">
            <div className={horLine} />
            <div className="call-block">
              <img src={Phone} alt="call" className="phone-icon" />
              <ContactUsContext.Consumer>
                {({ handleContactUsModalOpen }) => (
                  <a onClick={handleContactUsModalOpen} className="contact-us">Contact Us</a>
                )}
              </ContactUsContext.Consumer>
            </div>
          </div>
          <p className="hero__company-tagline">
            <span>Grow your business now with top-tier custom web solutions,</span>
             mobile apps, and cloud software
          </p>
        </div>
      </section>
    );
  }
}
