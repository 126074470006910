/* eslint-disable */
import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import ProjectCarouselItem from '../Components/Carousel/ProjectCarouselItem';

export default class ProjectCarousel extends Component {
  render() {
    const settings = {
      showThumbs: false,
      showArrows: false,
      useKeyboardArrows: true,
      showIndicators: true,
      showStatus: false,
      emulateTouch: true,
      width: '100%',
    };

    const { listOfProjects } = this.props;

    const projects = listOfProjects.map(data =>(
      <ProjectCarouselItem data={data} key={data.id}/>
    ));

    const isOneProject = Object.keys(this.props.listOfProjects).length === 1;    

    return (

      <div className='projects-carousel-wrapper'>
        {/* css class projects-carousel-left if wee will need an old implementation  */}
        <div className={isOneProject ? 'projects-carousel' : 'projects-carousel'}>
          {projects}
        </div>
        <div className='mobile-projects-carousel'>
          <Carousel {...settings}>
            {projects}
          </Carousel>
        </div>
      </div>
    )
  }
}
