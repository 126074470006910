/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/eduspike-logo.svg';
import HeaderScreen from '../../images/landings/eduspike-header-screen.png';
import MainBg from '../../images/landings/eduspike-main-bg.png';
import Team from '../../images/landings/eduspike_team.png';
import IconWeb from '../../images/landings/eduspike-icon-web.svg';
import IconMob from '../../images/landings/eduspike-icon-mobile.svg';
import TextBg from '../../images/landings/eduspike-yellow-rectangle.svg';

class Eduspike extends Component {
  render() {
    return (
      <div className="eduspike">
        <div className="landing-header eduspike__header">
          <div className="eduspike__header-logo">
            <img src={Logo} alt="Eduspike Logo" />
          </div>
          <div className="eduspike__header-wrapper">
            <div className="eduspike__header-text">
              <h1>Gamified lecture chat</h1>
              <p>Spike up your education! Interact,
                engage in lectures and earn rewards.</p>
            </div>
            <div className="eduspike__screen">
              <img className="screen" src={HeaderScreen} alt="Screen" />
            </div>
          </div>
        </div>
        <img className="eduspike__main-img" src={MainBg} alt="Screen" />
        <img className="eduspike__text-bg" src={TextBg} alt=""/>
        <div className="eduspike__text">
          <p>
            Education is leaving the auditorium and exploring the
            digital space. Future learning will be a mix
            of physical lectures and online studies.
            This calls for new ways to make students and teachers interact,
            collaborate and engage.
          </p>
          <p><strong>Eduspike</strong> rewards positive learning behavior and students
            earn Spikes and get real life benefits.</p>
        </div>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-team">
          <img src={Team} alt="Team" />
        </div>
        <div className="eduspike__bg-wrapper">
          <div className="eduspike__technologies-wrapper">
            <div className="eduspike__technologies reverse">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies">
                <span className="technology">AngularJS</span>
                <span className="technology">.NET</span>
                <span className="technology">WebApi</span>
                <span className="technology">EF</span>
                <span className="technology">Autofac</span>
                <span className="technology">jQuery</span>
                <span className="technology">WCF</span>
                <span className="technology">Chart.JS</span>
                <span className="technology">SignalR</span>
                <span className="technology">HTML5</span>
                <span className="technology">MS SQL Server</span>
                <span className="technology">CSS3</span>
                <span className="technology">Azure SQL</span>
                <span className="technology">LinqToSQL</span>
                <span className="technology">Ninject</span>
                <span className="technology">ASP.NET MVC</span>
                <span className="technology">Git</span>
                <span className="technology">React</span>
                <span className="technology">Redux</span>
                <span className="technology">WebPack</span>
                <span className="technology">ReactNative</span>
              </div>
            </div>
            <div className="eduspike__technologies">
              <img src={IconMob} alt="MOBILE"/>
              <div className="technologies">
                <span className="technology">React </span>
                <span className="technology">React Navigation</span>
                <span className="technology">React Native</span>
                <span className="technology">SignalR</span>
                <span className="technology">ES6</span>
                <span className="technology">Redux</span>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-info isPurple">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Hein Espen</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2016 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="http://eduspike.com" target="_blank">eduspike.com</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Eduspike;
