/* eslint-disable */
import React, { Component } from 'react';
import team from '../../../images/team.svg';
import bg from '../../../images/rectangle-bg.jpg';
import lines from '../../../images/lines.svg';
import './style.scss';
import { ContactUsContext } from "../../../App";

export default class TeamAugmentation extends Component {
  render() {
    return (
      <div className="service-item">
        <div className="rectangle-bg isTeamAugmentation-bg">
          <img src={bg} alt="rectangle-bg"/>
        </div>
        <div className="container-top">
          <img className="container-top__lines" src={lines} alt=""/>
          <h2 className="container-top__title">
            <img src={team} alt="Icon Team"/>
            TEAM AUGMENTATION
          </h2>
          <p>
            Dealing with a lack of resources is fierce. While you're looking for a perfect candidate,
            you might already have a team of top developers.
            We’re here to provide you with all the necessary resources for exceptional results!
          </p>
          <p>
            IT team augmentation is a quick and cost-effective way to satisfy your short-term and long-term staffing needs so that you always deliver your
            IT solutions on time. At Unicreo we are guided by high standards when it comes to hiring an employee.
            Our team consists of engineers, rather than coders, what makes us outstanding in complex tasks solving.
          </p>
          <p>
            Remote team work process is extremely flexible and adaptable, depending on your requirements and needs. Besides of qualified developers and
            QA engineers, we're able to provide you with project managers who have extensive experience in
            planning the project and its management. In our working process we rely on agile methodology,
            what means daily communication with client for a high degree of collaboration as well as full
            transparency. By using fixed schedule Sprints of 2-4 weeks, we stay focused on delivering an
            agreed subset of the product’s features while keeping an opportunity to constantly refine and
            reprioritize the overall product open.
          </p>
          <p> If you’re looking for a reliable partner, not just a team of coders, then&ensp;
            <ContactUsContext.Consumer>
              {({ handleContactUsModalOpen }) => (
                <a onClick={handleContactUsModalOpen} className="link-contact-us">
                  Contact us now!</a>
              )}
            </ContactUsContext.Consumer>
          </p>
        </div>
      </div>
    );
  }
}
