/* eslint-disable */
import React, { Component } from 'react';
import './style.scss'
import Logo from '../../images/landings/30pills-logo.svg';
import HeaderScreen from '../../images/landings/30pills-header-mobile@2x.png'
import MainScreen from '../../images/landings/30pills-main-nexus@2x.png';
import Team from '../../images/landings/30pills_team.png';
import HeaderBg from '../../images/landings/30p_back.jpg';

class ThirtyPills extends Component {
  render() {
    return (
      <div className="pills">
        <div className="landing-header pills__header">
          <img className="pills__header-bg" src={HeaderBg} alt=""/>
          <div className="pills__logo">
            <div className="pills-logo">
              <img src={Logo} loading="lazy" alt="30 Pills Logo" />
            </div>
            <h1>30 Pills
              <span>A medical app allows patients to keep track of
                taking medications and reminds to do this.</span>
            </h1>
          </div>
          <picture className="pills__header-screen">
            <img src={HeaderScreen} loading="lazy" alt="Screen" />
          </picture>
        </div>
        <div className="pills__main">
          <picture className="pills__main-screen">
            <img src={MainScreen} loading="lazy" alt="Screen" />
          </picture>
          <div className="pills__text">
            <h2>Communication between
              <span>patient & doctor</span>
            </h2>
            <p>(Video + Text + Image Exchange)</p>
          </div>
        </div>
        <hr className="separator"/>
        <div className="landing-team">
          <h3 className="sidelines">
            <span>TEAM</span>
            <span className="sidelines-border">TECH STACK</span>
          </h3>
          <img src={Team} loading="lazy" alt="Team" />
        </div>
        <div className="pills__technologies-wrapper">
          <div className="technologies">
            <span className="technology">ASP.NET Core Web Api</span>
            <span className="technology">MS SQL</span>
            <span className="technology">SignaIr</span>
            <span className="technology">React native</span>
            <span className="technology">React (with redux)</span>
            <span className="technology">ES6</span>
            <span className="technology">WEB RTC</span>
          </div>
        </div>
        <div className="landing-info isBlue">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>UNICREO</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2017 to now</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ThirtyPills;
