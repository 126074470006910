/* eslint-disable */
import React, { Component } from 'react';
import nextArrow from '../../images/nextArrow.svg';
import nextGrayArrow from '../../images/nextGrayArrow.svg';
import prevArrow from '../../images/prevArrow.svg';
import prevGrayArrow from '../../images/prevGrayArrow.svg';
import burger from '../../images/burger.svg';
import './style.scss';

export default class CarousesHeader extends Component {
  render() {
    const {
      projectName, projectDate, isFirst, isLast, onNext, onPrev,
    } = this.props;
    return (
      <div className="carousel-header-container">
        <div className="carousel-header-left">
          <div className="header-left-first-row">
            <span className="header-works">WORKS</span>
            <div className="carousel-header-icons">
              <div className="prev-arrow icons" onClick={onPrev}>
                <img src={isFirst ? prevGrayArrow : prevArrow} alt="" />
              </div>
              {/* <div className="burger-menu icons">
                <img src={burger} alt="" />
              </div> */}
              <div className="left-arrow icons" onClick={onNext}>
                <img src={isLast ? nextGrayArrow : nextArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ opacity: 0 }}>Hidden</div>
        </div>
        <div className="carousel-header-right">
          <span className="header-right-project">{projectName || 'Test'}</span>
          <span className="header-right-date">{projectDate || 'Test'}</span>
        </div>
      </div>
    );
  }
}
