/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Tab from './Tab';
import { withRouter } from 'react-router-dom'
import { TabsUrsl } from '../../Mock';
import { ContactUsContext } from '../../App';
const classNames = require('classnames');

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          id: 0,
          name: 'Services',
          active: false,
          isDropDown: true,
          url: TabsUrsl.Sevices
        },
        {
          id: 1,
          name: 'Works',
          active: false,
          url: TabsUrsl.Works
        },
        {
          id: 2,
          name: 'Approach',
          active: false,
          isDropDown: true,
          url: TabsUrsl.Approach
        },
        {
          id: 3,
          name: 'About Us',
          active: false,
          url: TabsUrsl.AboutUs
        },
        // {
        //   id: 4,
        //   name: 'Contacts',
        //   active: false,
        // },
      ],
    };
    const { location } = this.props;
    const { tabs } = this.state;
    const activeTabUrl = location.pathname.trim().split('/')[1];
    if (activeTabUrl) {
      let tab = tabs.find(t => t.url === activeTabUrl);
      if (typeof  tab !== 'undefined')
          tab.active = true;
    }
  }

  componentDidMount() {
    const { location } = this.props;
    const { tabs } = this.state;
    let activeTabUrl = location.pathname.trim().split('/')[1];

    if (activeTabUrl) {
      this.onTabClick(tabs.find(t => t.url === activeTabUrl), true);
    }

  }

  componentWillReceiveProps(nextProps) {

    const { location } = nextProps;
    const { tabs } = this.state;
    let activeTabUrl = location.pathname.trim().split('/')[1];

    if (activeTabUrl)
    {
      let tab = tabs.find(t => t.url === activeTabUrl);
      if (typeof  tab !== 'undefined')
          tab.active = true;
    }

    if (this.props.activeTab !== nextProps.activeTab) {
      this.onTabClick(this.state.tabs.find(t => t.id === nextProps.activeTab),  false)
    }
    if (!this.props.isHomePage && nextProps.isHomePage) {
      this.clearActiveTab()
    }
  }



  clearActiveTab = () => {
    const tabs = [...this.state.tabs];
    tabs.forEach(t => t.active = false);
    this.setState({ tabs });
  };

  onTabClick = (handleMobileMenuSwitch) => (tab, selectDropItem = false) => {
    if (!tab.isDropDown) handleMobileMenuSwitch(false)();
    if (tab.isDropDown && !selectDropItem) return;
    const tabs = [...this.state.tabs];
    tabs.forEach(t => t.active = false);
    const t = tabs.find(t => t.id === tab.id);
    t.active = true;
    this.setState({ tabs });
    if (!selectDropItem) this.props.history.push(`/${tab.url}`)
  };

  render() {
    const { tabs } = this.state;
    return (
      <ContactUsContext.Consumer>
        {({ handleContactUsModalOpen, state, handleMobileMenuSwitch }) => (
          <div className={state.isMobileMenuShown ? 'tabs-container-mobile' : 'tabs-container'}>
            {tabs.map((tab, index) => (<Tab tab={tab} key={index} onTabClick={this.onTabClick(handleMobileMenuSwitch)} />))}
            <div className="contact-us-btn" onClick={handleContactUsModalOpen}>
              <span className='contact-us-text'>Contact Us</span>
            </div>
          </div>
        )}
      </ContactUsContext.Consumer>
    );
  }
}

export default withRouter(Tabs)
