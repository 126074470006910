/* eslint-disable */
import React, { Component } from 'react';
import consulting from '../../../images/consulting.svg';
import bg from '../../../images/rectangle-bg.jpg';
import lines from '../../../images/lines.svg';
import './style.scss';

export default class Consulting extends Component {
  render() {
    return (
      <div className="service-item isConsulting">
        <div className="rectangle-bg">
          <img src={bg} alt="rectangle-bg"/>
        </div>
        <div className="container-top">
          <img className="container-top__lines" src={lines} alt=""/>
          <h2 className="container-top__title">
            <img src={consulting} alt="Icon Consulting"/>
            CONSULTING
          </h2>
          <p>
            We've gained a vast amount of experience while working with
            our clients throughout the whole product development process.
            We know what the challenges are both in software development
            and business. Our team of consultants ready to dive deep into
            your core business domain, involve subject matter experts
            where needed, summarize findings and help to establish the
            right custom strategy for product development - to get
            expected results in the long term perspective.
          </p>
          <p>
            Our experts can define and oversee outsourcing activities
            for your application development project or provide you
            with strategic software developing consulting on different
            aspects of the entire process, including planning, budget
            and timeline estimation, scalability, risk management,
            quality assessment.
          </p>
        </div>
      </div>
    );
  }
}
