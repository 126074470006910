/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      description: '',
    };
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  onChange = (event) => {
    const { name, value } = event.target;
    if (name == "email") {
      this.refs.emailInput.style.borderColor = this.validateEmail(value) ? "green" : "red";
    }

    this.setState({ [name]: value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (!this.validateEmail(this.state.email)) {
      alert("Invalid email");
      return;
    }
    const request = { ...this.state };
    this.props.onSubmit(request);
    this.setState({
      name: '', email: '', phone: '', description: '',
    });
  };

  render() {
    const {
      name, email, phone, description,
    } = this.state;
    return (
      <form onSubmit={this.onSubmit} className="form">
        <div className="form__inputs">
          <label htmlFor="name">
            Full Name
            <input
              value={name}
              name="name"
              onChange={this.onChange}
              placeholder="enter your full name"
              className="form__input"
            />
          </label>
          <label htmlFor="email">
            Email Address<span style={{ color: "red" }}>*</span>
            <input
              value={email}
              name="email"
              onChange={this.onChange}
              placeholder="enter your email"
              className="form__input"
              ref="emailInput"
            />
          </label>
          <label htmlFor="phone">
            Phone Number
            <input
              value={phone}
              name="phone"
              onChange={this.onChange}
              placeholder="enter your phone"
              className="form__input"
            />
          </label>
        </div>
        <p>Project brief</p>
        <textarea
          value={description}
          name="description"
          placeholder="tell us about your project"
          onChange={this.onChange}
          className="form__description"
        />
        <button type="submit" className="form__btn">
          <span>Submit</span>
        </button>
      </form>
    );
  }
}