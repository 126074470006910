/* eslint-disable */
import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import CommentCarouselItem from "../Components/Carousel/CommentCarouselItem";

export default class CommentsCarousel extends Component {
  render() {
    const settings = {
      showThumbs: false,
      showArrows: false,
      useKeyboardArrows: false,
      showIndicators: true,
      showStatus: false,
      emulateTouch: true,
      width: '100%',
    };

    const { listOfComments } = this.props;

    const comments = listOfComments.map(data =>(
      <CommentCarouselItem data={data} key={data.id}/>
    ));

    return (
      <div className='comments-carousel-wrapper'>
        <div className='comments-carousel'>
          {comments}
        </div>
      </div>
    )
  }
}
