/* eslint-disable */
import React, { Component } from 'react';
import './App.scss';
import Services from './Containers/Services';
import CarouselContainer from './Containers/CarouselContainer';
import Team from './Containers/Team';
import Hero from './Components/Hero/Hero';
import Footer from './Components/Footer/Footer';

class Home extends Component {

  render() {

    return (
      <div className="App">
        <div className="container">
          <Hero />
          <section id="1">
            <h3 className="services-title">SERVICES</h3>
            <div className="services-container">
              <Services />
            </div>
          </section>
          <div id="2" />
          <section>
            <div className="carousel-wrapper">
              <CarouselContainer />
            </div>
          </section>
          <div id="3" />
          <section>
            <Team />
          </section>
        </div>
      </div>
    );
  }
}

export default Home;
