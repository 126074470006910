/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Header from '../../images/landings/getwell-header.jpg';
import Logo from '../../images/landings/getwell-logo.png';
import Team from '../../images/landings/getwell_team.png';
import IconWeb from '../../images/landings/getwell-icon-web.svg';

class Getwell extends Component {
  render() {
    return (
      <div className="getwell">
        <div className="landing-header getwell__header">
          <img className="getwell__header-bg" src={Header} alt="Getwell" />
        </div>
        <div className="getwell__main">
          <div className="getwell__logo">
            <img src={Logo} alt="Getwell Logo"/>
          </div>
          <p>GetWell is a modern electronic health record system which
            provides an opportunity to conduct an electronic patient
            record and clinical data. The software provides the
            features which are needed to run ophthalmology
            practice smoothly.</p>
          <p>GetWell  is easy to use with great customer service support.</p>
        </div>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-team">
          <img src={Team} alt="Team" />
        </div>
        <div className="getwell__bg-wrapper">
          <div className="getwell__technologies-wrapper">
            <img src={IconWeb} alt="WEB"/>
            <div className="technologies">
              <span className="technology">Postgre SQL</span>
              <span className="technology">CoffeeScript</span>
              <span className="technology">JavaScript</span>
              <span className="technology">HTML</span>
              <span className="technology">WebSocket</span>
              <span className="technology">SASS</span>
              <span className="technology">Ruby on Rails 4</span>
              <span className="technology">Git</span>
              <span className="technology">HTML5</span>
              <span className="technology">CSS</span>
              <span className="technology">Bootstrap</span>
              <span className="technology">JQuery</span>
              <span className="technology">KnokautJS</span>
              <span className="technology">Redis</span>
              <span className="technology">WkhtmlToPdf</span>
              <span className="technology">Heroku</span>
            </div>
          </div>
        </div>
        <div className="landing-info isBrownGrey">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>James</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>2015-2016</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Getwell;
