/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/seequre-logo@2x.png';
import ScreenMac from '../../images/landings/seequre-screen-mac@2x.png';
import ScreenMob from '../../images/landings/seequre-screen-mob@2x.png';
import Team from '../../images/landings/seequre_team.png';
import IconIos from '../../images/landings/seequre-icon-ios.svg'
import IconWeb from '../../images/landings/seequre-icon-web.svg';
import IconAndroid from '../../images/landings/seequre-icon-android.svg';
import Bg from '../../images/landings/seequre_back.jpg';

class Seequre extends Component {
  render() {
    return (
      <div className="seequre">
        <div className="landing-header seequre__header">
          <img src={Bg} alt="Seequre"/>
          <div className="seequre__header-block">
            <div className="seequre__logo">
              <img src={Logo} alt="Seequre Logo" />
            </div>
            <h1 className="seequre__header-title">
              Secure video communication
              <span>and instant messaging application for small business companies.</span>
            </h1>
          </div>
        </div>
        <div className="seequre__main">
          <img src={ScreenMac} sizes="40vw" alt="Screen" />
          <p className="seequre__text">
            Primary purpose of the product is to provide functionality for<span> secure video </span>
            communication and instant messaging for small
            business companies.
            Essential part is to provide tight integration with MDaemon
            mail server
          </p>
        </div>
        <h2 className="seequre__main-title">Safe Video Communications develops
          <span>email server integrated video conferencing
            solutions for the SMB market.</span></h2>
        <div className="seequre__main-second">
          <img className="seequre__main-screen" src={ScreenMob}  alt="Screen" />
          <div className="seequre__description">
            <p>
              <strong>Seequre video chat</strong> takes your email server installation a bit further,
            </p>
            <p>
              securing easy collaboration with clients, partners and company members.
            </p>
            <p>
              Conduct and manage video conferences - with added security.
            </p>
          </div>
        </div>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-team">
          <img src={Team} alt="Team" />
        </div>
        <div className="seequre__technologies-wrapper">
          <div className="seequre__technologies reverse column">
            <img src={IconWeb} alt="WEB"/>
            <div className="technologies">
              <span className="technology">MS SQL Server</span>
              <span className="technology">.NET</span>
              <span className="technology">WebSocket</span>
              <span className="technology">React</span>
              <span className="technology">CSS</span>
              <span className="technology">HTML</span>
              <span className="technology">ASP.NET Core</span>
              <span className="technology">Git</span>
              <span className="technology">EntityFramework</span>
              <span className="technology">SignalIR</span>
              <span className="technology">SASS</span>
              <span className="technology">WebPack</span>
              <span className="technology">Flux</span>
              <span className="technology">WebRTC</span>
              <span className="technology">Swagger</span>
              <span className="technology">Wix Toolset</span>
            </div>
          </div>
          <div className="seequre__technologies">
            <img src={IconAndroid} alt="ANDROID" />
            <div className="technologies">
              <span className="technology">RealmDB</span>
              <span className="technology">RxJava2</span>
              <span className="technology">RxBinding</span>
              <span className="technology">MoxyMVP</span>
              <span className="technology">Dagger2</span>
              <span className="technology">WebRTC</span>
              <span className="technology">SignalR</span>
              <span className="technology">Firebase</span>
              <span className="technology">Custom Widgets</span>
              <span className="technology">Java</span>
              <span className="technology">MVP</span>
              <span className="technology">DI(dagger)</span>
              <span className="technology">Retrofit</span>
              <span className="technology">FCM</span>
            </div>
          </div>
          <div className="seequre__technologies reverse column">
            <img src={IconIos} alt="IOS" />
            <div className="technologies">
              <span className="technology">RealmDB</span>
              <span className="technology">WebRTC</span>
              <span className="technology">RxSwift</span>
              <span className="technology">RxRealm</span>
              <span className="technology">FCM</span>
              <span className="technology">Swift</span>
              <span className="technology">SignalIR</span>
            </div>
          </div>
        </div>
        <div className="landing-info isDarkBlue">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Tomas</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2016 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="https://global.seequre.video" target="_blank">global.seequre.video</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Seequre;
