/* eslint-disable */
import React, { Component } from 'react';
import './style.scss';
import Logo from '../../images/landings/blirfint-logo.svg';
import Bg from '../../images/landings/blirfint_background.jpg';
import FirstScreen from '../../images/landings/blirfint-first-screen@2x.png';
import SecondScreen from '../../images/landings/blirfint-screen-map@2x.png';
import Team from '../../images/landings/blirfint_team.png';
import IconMob from '../../images/landings/blirfint-icon-mobile.svg';
import IconWeb from '../../images/landings/blirfint-icon-web.svg';

class BlirFint extends Component {
  render() {
    return (
      <div className="blirfint">
        <div className="landing-header blirfint__header">
          <img className="blirfint__bg" src={Bg} alt="Blirfint"/>
          <h1 className="blirfint__logo">
            <img src={Logo} alt="BlirFint Logo"/>
            Craftsmen and home owners <br/>
            collaboration service.
          </h1>
        </div>
        <div className="blirfint__main">
          <div className="blirfint__main-block">
            <h2 className="blirfint__title">Do you need a craftsman -
              <span>NOW?</span></h2>
            <div className="blirfint__description">
              <p>
                Sometimes it is urgent to get skilled professionals. Download <a href="/">Blir Fint</a> and get an
                overview of all available craftsmen near you!
              </p>
              <ul>
                <li>Posting a job is free and non-binding</li>
                <li>Easy communication via mobile</li>
                <li>By using our chat, you do not have to communicate via your own private channels.</li>
                <li>Scure payment. We handle your payments and "freeze" the money until you are
                  happy with the job!</li>
              </ul>
              <p>Get yourself a craftsman today!</p>
            </div>
          </div>
          <div className="blirfint__main-block">
            <img src={FirstScreen} alt="Screen" align="right"/>
          </div>
        </div>
        <div className="blirfint__block">
          <img src={SecondScreen} alt="Screen"/>
          <h4 className="blirfint__text">An app for connecting <span>clients</span><br/>
            with <span>service providers</span></h4>
        </div>
        <h3 className="sidelines">
          <span>TEAM</span>
          <span className="sidelines-border">TECH STACK</span></h3>
        <div className="landing-wrapper">
          <div className="landing-team">
            <img src={Team} alt="Team"/>
          </div>
          <div className="blirfint__technologies-wrapper">
            <div className="blirfint__technologies">
              <img src={IconMob} alt="MOBILE"/>
              <div className="technologies isRightAlign">
                <span className="technology">Swift</span>
                <span className="technology">RxJava</span>
                <span className="technology">Firebase Cloud Messaging</span>
                <span className="technology">Retrofit</span>
                <span className="technology">Dagger2</span>
                <span className="technology">Location Services</span>
              </div>
            </div>
            <div className="blirfint__technologies">
              <img src={IconWeb} alt="WEB"/>
              <div className="technologies isRightAlign">
                <span className="technology">Entity Framework 6</span>
                <span className="technology">ASP. NET WEB API 2</span>
                <span className="technology">Google maps API</span>
                <span className="technology">Autofac</span>
                <span className="technology">WebApi</span>
                <span className="technology">Chart.JS</span>
                <span className="technology">ASP. NET MVC 5</span>
                <span className="technology">MS Bot Framework</span>
                <span className="technology">Stripe API</span>
                <span className="technology">Angular 2+</span>
                <span className="technology">Bootstrap</span>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-info isBlack">
          <table>
            <tbody>
            <tr>
              <th>Client:</th>
              <td>Jan Harald</td>
            </tr>
            <tr>
              <th>Project was started:</th>
              <td>from 2016 to now</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td><a href="https://www.blirfint.no" target="_blank">blirfint.no</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BlirFint;
